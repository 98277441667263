footer {
    padding: 30px 0;
    background-color: #f7f7f7;
    .m-men {
        display: flex;
        justify-content: flex-start;
        // flex-wrap: wrap;
        ul {
            min-width: 110px;
            li {
                padding-bottom: 12px;
                a {
                    font-family: Lato-Regular;
                    font-size: 18px;
                    text-transform: capitalize;
                }
            }
        }
    }
    .dis-cover {
        p {
            margin-top: 0px;
            font-family: 'Montserrat-SemiBold';
            font-size: 18px;
        }
        .copyright {
            font-size: smaller;
            font-family: 'Montserrat-Light';
            margin-top: 30px;
        }
        .btn-image img {
            width: 140px;
        }
    }
}

.footer-inf li {
    font-size: 15px;
    margin-bottom: 3px;
    .f-font-famB {
        font-family: 'Lato-Bold';
    }
    .f-font-famL {
        font-family: 'Lato-Light';
    }
}

.footer-menu li a {
    font-size: 16px;
    color: #000000;
    font-family: 'Montserrat-medium';
}

@media only screen and (max-width: 800px) {
    .footer-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 15px;
        text-transform: capitalize;
    }
}

.footer-icon i {
    padding: 6px 10px;
}

.copyr p {
    text-align: right;
    font-size: 12px;
    margin-top: -35px;
}

.footer-icon {
    display: flex;
    justify-content: center;
    width: 100px;
    margin: 0 auto;

    i:nth-child(1) {
        cursor: pointer;
    }
}

#weight-shift-plan {
    background-color: white;
    padding: 20px 0;
    padding-left: 140px;
    font-size: 25px;
    font-family: 'Lato-Bold';
    color: #8DD54E;
    text-transform: uppercase;
    border-top: 2px solid #8DD54E;
    @media only screen and (max-width: 900px) {
        padding-left: 50px;
        font-size: 18px;
    }
}

@media only screen and (max-width: 450px) {
    .footer-col .footer-menu {
        li {
            width: 40%;
            text-align: left;
            padding-left: 30px;
        }
    }
    .footer-col .footer-menu li {
        width: 30%;
        text-align: left;
        padding-left: 10px;
    }
    footer .m-men ul {
        width: 100%;
        display: block;
    }
    .footer-icon {
        display: flex;
        justify-content: center;
        width: 100px;
        align-items: center;
        margin: 0 auto;
    }
}