    .marginss {
        margin-left: 5%;
        margin-right: 10%;
    }
    
    .marginsLast {
        margin: 0% 10%;
    }
    
    .fusion-exe-detail-warper {
        overflow-x: hidden;
        .banner-secss {
            position: relative;
            img {
                height: 250px !important;
                width: 100%;
                object-fit: cover;
            }
            &::before {
                background-color: #2827278e;
                content: '';
                display: block;
                height: 250px !important;
                position: absolute;
                width: 100%;
            }
            .content-sec {
                position: absolute;
                width: unset !important;
                color: #fff;
                top: 125px !important;
                padding-left: 115px;
                p:nth-child(1) {
                    font-size: 16px;
                    font-family: 'Montserrat-Medium';
                    opacity: .5;
                }
                h1 {
                    font-family: 'Montserrat-Bold';
                    font-size: 48px !important;
                    margin-bottom: 80px;
                    text-transform: uppercase;
                    @media only screen and (max-width: 1000px) {
                        font-size: 32px !important;
                    }
                    @media only screen and (max-width: 450px) {
                        font-size: 22px !important;
                    }
                }
            }
            .right-img {
                position: absolute;
                right: 0px;
                right: 65px;
                top: 100px !important;
                img {
                    width: 100px;
                    height: 100px !important;
                }
            }
        }
        .info-wraper {
            background-color: #eff8e5;
            margin-top: -6px;
            padding: 12px 100px;
            .info-sec {
                display: flex;
                align-items: center;
                padding: 10px;
                margin: 5px 15px;
                background: #def2cd;
                border-radius: 45px 0 0 0;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                p {
                    margin: 0;
                }
                .info-left {
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    p {
                        margin-left: 15px;
                    }
                }
                p:nth-last-child(2) {
                    position: relative;
                }
                p:nth-last-child(2)::before {
                    content: '|';
                    position: absolute;
                    left: -7px;
                    color: #55b700;
                    font-weight: 600;
                }
                @media only screen and (max-width: 1400px) {
                    justify-content: center;
                    p:nth-last-child(2) {
                        text-align: center;
                    }
                    p:nth-last-child(2)::before {
                        content: '';
                        position: absolute;
                        left: -7px;
                        color: unset;
                        font-weight: unset;
                    }
                }
            }
            .tb-info {
                display: flex;
                justify-content: right;
                align-items: center;
                margin-bottom: 5px;
                span {
                    font-family: 'Lato-Bold';
                    font-size: 16px;
                    margin-right: 10px;
                    width: 100px;
                    display: block;
                    text-transform: uppercase;
                    text-align: right;
                }
                img {
                    width: 50px;
                }
                @media only screen and (max-width: 1400px) {
                    position: relative;
                    top: 25%;
                    margin-bottom: 25px;
                }
            }
            .activeimg {
                background-image: url(../../assets/images/personHealth.png);
                background-repeat: no-repeat;
                width: 50px;
                height: 50px;
                background-size: 50px 50px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 50px;
                }
            }
            .app-btn {
                display: flex;
                flex-wrap: wrap;
                button {
                    // background-image: linear-gradient(#fbfbfb, #b9b9b7);
                    // border: 0;
                    // border-radius: 10px;
                    // padding: 2px 10px;
                    // margin-right: 10px;
                    // display: flex;
                    // align-items: center;
                    // outline: none;
                    // cursor: pointer;
                    // img {
                    //     width: 15px;
                    //     margin-right: 5px;
                    // }
                    border: 1px solid #c8c9c6;
                    border-radius: 6px;
                    padding: 2px 10px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    outline: none;
                    background: #def2cd;
                    color: #62B01E;
                    font-weight: 600;
                    margin-top: 8px;
                }
            }
        }
        @media only screen and (max-width: 480px) {
            .info-sec {
                p:nth-last-child(2) {
                    font-size: 14px;
                }
            }
            .app-btn {
                font-size: 12px;
            }
        }
        .vedio-sec {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin-top: 60px;
            .vedio-con {
                width: 65%;
                .vedios {
                    position: relative;
                    img:nth-child(1) {
                        height: 500px;
                        width: 100%;
                    }
                    img:nth-child(2) {
                        position: absolute;
                        top: 40%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }
                .vedios-pagi {
                    display: flex;
                    justify-content: center;
                    padding: 15px 5px;
                    align-items: center;
                    flex-wrap: wrap;
                    p {
                        margin: 0;
                        font-family: 'Lato-Regular';
                        font-size: 20px;
                        text-transform: uppercase;
                        margin-right: 20px;
                    }
                    span {
                        display: flex;
                        width: 25px;
                        height: 25px;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                        border-radius: 50%;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        margin: 0 4px;
                        cursor: pointer;
                    }
                }
            }
            .profile-info {
                width: 33%;
                .info-con {
                    display: flex;
                    background: #F2F2F2;
                    padding: 30px 30px 10px;
                    .profile-img {
                        padding: 0 15px 0 0;
                        img {
                            border-radius: 50%;
                            min-width: 120px;
                            height: 120px;
                        }
                        h3 {
                            font-size: 16px;
                            padding: 10px 0;
                            font-family: 'Montserrat-Medium';
                            text-align: center;
                        }
                    }
                    .profile-text {
                        color: #595959;
                        label {
                            font-size: 16px;
                            font-family: 'Lato-Bold';
                        }
                        span {
                            font-size: 16px;
                            margin-left: 10px;
                            font-family: 'Lato-Regular';
                        }
                        p {
                            font-size: 16px;
                            font-family: 'Lato-Light';
                        }
                    }
                }
                .profile-wrap {
                    padding: 0 30px;
                    font-size: 16px;
                    color: #595959;
                    padding-left: 50px;
                    .profile-health {
                        text-align: center;
                        h2 {
                            font-family: 'Lato-Light';
                            font-size: 16px;
                            border-bottom: 1px solid #DFDFDF;
                            display: inline-block;
                            padding: 10px 0px 5px 0px;
                        }
                    }
                    .health {
                        display: flex;
                        justify-content: flex-start;
                        gap: 50px;
                        flex-wrap: wrap;
                        margin-left: 20px;
                        p {
                            margin-bottom: 5px;
                            font-family: 'Lato-Light';
                        }
                        @media only screen and (max-width: 1200px) {
                            justify-content: center;
                        }
                    }
                    .text-cmp {
                        position: relative;
                        left: 46%;
                        color: #bababa;
                        @media only screen and (max-width: 820px) {
                            left: 58%;
                        }
                        @media only screen and (max-width: 540px) {
                            left: 23%;
                        }
                    }
                }
                .shealth {
                    display: flex;
                    padding: 0px 15px;
                    flex-wrap: wrap;
                    font-family: 'Lato-Light';
                    span {
                        margin-left: 50px;
                    }
                }
                .work-health {
                    display: flex;
                    padding: 0px 15px;
                    flex-wrap: wrap;
                    font-family: 'Lato-Light';
                    p {
                        margin-right: 50px;
                    }
                    button {
                        border: 1px solid #595959;
                        background: no-repeat;
                        padding: 0px 12px;
                        border-radius: 5px;
                        margin: 0 4px;
                        outline: none;
                        cursor: pointer;
                    }
                    .hactive {
                        border: 1px solid #9ad962;
                        color: #9ad962;
                    }
                }
                .max-health {
                    display: flex;
                    padding: 0px 15px;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    font-family: 'Lato-Light';
                    p {
                        margin: 0;
                    }
                    img {
                        margin: 0 5px;
                    }
                    a {
                        font-size: 15px;
                        color: #00AACC;
                    }
                }
            }
            @media only screen and (max-width: 1500px) {
                .vedio-con {
                    width: 55%;
                    .vedios {
                        img {
                            object-fit: cover;
                        }
                    }
                }
                .profile-info {
                    width: 43%;
                }
            }
            @media only screen and (max-width: 1200px) {
                .vedio-con {
                    width: 100%;
                    .vedios {
                        img {
                            object-fit: cover;
                        }
                    }
                }
                .profile-info {
                    width: 100%;
                    .max-health {
                        justify-content: center;
                        gap: 38px;
                    }
                }
            }
            @media only screen and (max-width: 600px) {
                .vedio-con {
                    width: 100%;
                    .vedios {
                        img {
                            object-fit: cover;
                        }
                    }
                }
                .profile-info {
                    width: 100%;
                    .max-health {
                        justify-content: flex-start;
                        gap: 10px;
                    }
                }
            }
        }
        .cluster {
            position: absolute;
            right: -58px;
            margin-top: 84px;
            cursor: pointer;
            li {
                padding: 20px 0;
                img {
                    width: 70px;
                }
            }
        }
        .sigment-img {
            position: relative;
            margin: 20px 0;
            .nav-tabs {
                border-bottom: 0
            }
            .nav-tabs .nav-link.active {
                color: #495057;
                background-image: linear-gradient(#e9e9e9, #fffff9);
                border-color: #dee2e6;
            }
            .nav-tabs {
                border-bottom: 0;
                border: 1px solid #dee2e6;
                display: inline-flex;
                padding: 0;
                border-radius: 4px;
                margin: 25px 0 !important;
                @media only screen and (max-width: 800px) {
                    border: unset;
                }
            }
            .nav-link {
                display: block;
                padding: 0.2rem 2rem;
                color: #8C8C8C;
                font-family: 'Montserrat-Semibold';
                font-size: 20px;
                background-color: rgb(250, 250, 250);
            }
            .sigment-img-right {
                position: absolute;
                right: 0;
                top: 10px;
                width: 50px;
            }
            .sig-card {
                font-family: 'Montserrat-Bold';
                font-size: 12px;
                margin-top: 10px;
                text-align: center;
                color: #8C8C8C;
                display: flex !important;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                img {
                    width: 220px;
                    height: 220px;
                    border-radius: 15px;
                }
                p {
                    margin-bottom: 5px;
                    text-align: center;
                }
            }
        }
        .cust-review {
            position: relative;
            margin-top: 30px;
            font-family: 'Montserrat-Semibold';
            text-align: center;
            h1 {
                font-size: 32px;
                color: #8C8C8C;
                margin-bottom: 50px;
                text-transform: uppercase;
            }
            .write-review {
                position: absolute;
                right: 0;
                top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @media (max-width: 700px) {
                    display: none;
                }
                p {
                    font-family: 'Lato-Light';
                    font-size: 12px;
                    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
                    padding: 3px 15px;
                    text-transform: uppercase;
                    border-radius: 5px;
                }
                img {
                    width: 25px;
                    cursor: pointer;
                }
            }
        }
        .review-card {
            margin: 10px 10px;
            position: relative;
            .card-review {
                padding: 15px;
                border: 1px solid rgb(197, 195, 195);
                border-radius: 4px;
                margin: 0 20px;
                .head-sec {
                    display: flex;
                    align-items: center;
                    .head-info {
                        margin-left: 15px;
                        p {
                            font-family: 'Lato-Bold';
                            font-size: 14px;
                            margin: 0;
                        }
                        h1 {
                            font-family: 'Lato-Bold';
                            font-size: 20px;
                        }
                    }
                }
                .star-info {
                    display: flex;
                    justify-content: space-between;
                    margin: 15px 10px 10px 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgb(226, 226, 226);
                    i {
                        color: #FFAE31;
                    }
                    .no-colr {
                        color: #B5B5B5;
                    }
                    p {
                        margin: 0;
                        font-family: 'Lato-Regular';
                        font-size: 14px;
                        color: #24231F;
                    }
                }
                .info2 {
                    padding: 10px;
                    position: relative;
                    p {
                        padding: 10px 15px;
                        margin: 0;
                        color: #24231F;
                        font-family: 'Lato-Light';
                        font-size: 18px;
                    }
                    .img2 {
                        position: absolute;
                        right: 0;
                    }
                    span {
                        display: block;
                        text-align: right;
                        padding-top: 30px;
                        font-family: 'Lato-Light';
                        font-size: 12px;
                    }
                }
            }
        }
        .review-card::before {
            content: '-';
            position: absolute;
            top: -29px;
            left: 29px;
            font-size: 38px;
        }
        .exercise-programs {
            padding: 30px 0;
            h1 {
                font-family: 'Montserrat-Bold';
                font-size: 32px;
                text-transform: uppercase;
                text-align: center;
                color: #8C8C8C;
                margin: 30px 0;
            }
            .exercise-pro {
                margin: 0 20px;
                .sig-card {
                    font-family: 'Montserrat-Bold';
                    font-size: 14px;
                    text-align: center;
                    margin: 0 20px;
                    // img {
                    //     width: 250px;
                    //     height: 250px;
                    // }
                    p {
                        margin: 5px 0px;
                        margin-right: 60px;
                        text-align: center;
                        font-family: 'Avenir-medium';
                        font-size: 20px;
                    }
                }
            }
        }
        
        
        .slick-dots li.slick-active button:before {
            font-size: 15px;
            color: blue;
        }
        .slick-dots[data-current-slide="3"] {
            bottom: -20px;
        }
        .hide-text {
            display: none;
        }
        .slick-active {
            .tb-info {
                display: flex;
                justify-content: right;
                align-items: center;
                margin-bottom: 5px;
                span {
                    font-family: 'Lato-Bold';
                    font-size: 16px;
                    margin-right: 10px;
                    width: 100px;
                    display: block;
                    text-transform: uppercase;
                    text-align: right;
                    .hide-text {
                        display: block;
                    }
                }
                img {
                    width: 70px;
                }
                .dot-text1 {
                    color: #51be58;
                }
                .dot-text2 {
                    color: #fc00fe;
                }
                .dot-text3 {
                    color: #fec604;
                }
                .dot-text4 {
                    color: #0491fe;
                }
            }
        }
    }
    
    .air-content-show {
        display: block;
    }
    
    .air-content-hide {
        display: none;
    }
    
    @media (max-width:1025px) {
        .fusion-exe-detail-warper .vedio-sec .cluster {
            display: none;
        }
        .fusion-exe-detail-warper .info-wraper .app-btn {
            justify-content: center;
            button {
                margin-bottom: 5px;
            }
        }
        .fusion-exe-detail-warper .info-wraper .app-btn button {
            margin-bottom: 10px;
        }
        .fusion-exe-detail-warper .review-card .card-review .head-sec {
            flex-wrap: wrap;
            text-align: center;
            img {
                margin: 0 auto;
            }
        }
        .marginss {
            margin-left: 15px;
            margin-right: 15px;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .shealth {
            justify-content: center;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .work-health {
            justify-content: center;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .profile-wrap .text-cmp {
            text-align: center;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .max-health {
            padding: 0px 0px;
        }
    }
    
    @media (max-width:768px) {
        .fusion-exe-detail-warper .info-wraper {
            padding: 12px 20px;
        }
        .fusion-exe-detail-warper .info-wraper .app-btn {
            justify-content: center;
            button {
                margin-bottom: 5px;
            }
        }
        .fusion-exe-detail-warper .vedio-sec .vedio-con {
            width: 100%;
        }
        .fusion-exe-detail-warper .vedio-sec .vedio-con .vedios {
            img:nth-child(2) {
                width: 50px;
            }
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info {
            width: 100%;
        }
        .fusion-exe-detail-warper .vedio-sec .cluster {
            display: none;
        }
        .fusion-exe-detail-warper .sigment-img .sig-card img {
            width: 120px;
            height: 120px;
        }
        .fusion-exe-detail-warper .sigment-img .sig-card {
            width: 123px !important;
        }
        .fusion-exe-detail-warper .sigment-img .sigment-img-right {
            top: -41px;
        }
        .fusion-exe-detail-warper .sigment-img .nav-link {
            width: 100%;
            text-align: center;
            font-size: 14px;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .profile-wrap {
            margin-bottom: 30px;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .work-health {
            margin-bottom: 10px;
        }
        .fusion-exe-detail-warper .review-card {
            margin: 0;
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .profile-wrap .health {
            margin: 0 auto;
        }
        .fusion-exe-detail-warper .slick-dots {
            position: static;
            display: flex !important;
            justify-content: center;
            @media only screen and (max-width: 600px) {
                display: none !important;
            }
        }
        .fusion-exe-detail-warper .vedio-sec .profile-info .profile-wrap {
            padding: 0 0px;
            font-size: 16px;
        }
        .fusion-exe-detail-warper .banner-secss .content-sec {
            position: absolute;
            width: 100%;
            color: #fff;
            top: 50px;
            padding-left: 40px;
            z-index: 9;
        }
        .fusion-exe-detail-warper .sigment-img .nav-tabs {
            width: 100%;
        }
        .fusion-exe-detail-warper .sigment-img .nav-tabs .nav-item {
            width: 50%;
            border-bottom: 1px solid #b7b7b7;
            margin-bottom: 20px;
        }
    }
    
    .view-more {
        text-align: right;
        font-family: 'Lato-Light';
        font-size: 16px;
        padding-right: 25px;
        margin-bottom: 20px;
    }