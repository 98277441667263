@import '../../assets/styles/_breakpoints.scss';
.guide-text {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    position: absolute;
    top: 24%;
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    .cente-text1 {
        .line {
            width: 90px;
            height: 5px;
            background-color: #fff;
            margin: 0 0 5px 5px;
        }
        color: #fff;
        h1 {
            text-align: left;
            font-family: 'Montserrat-Medium';
            font-size: 56px;
            margin-bottom: 35px;
        }
        p {
            text-align: left;
            font-family: 'Lato-Regular';
            font-size: 20px;
            width: 50%;
            padding-left: 15px;
        }
    }
    .right-img {
        span {
            width: 300px;
            cursor: pointer;
            display: block;
            text-align: center;
            font-family: 'Lato-Regular';
            font-size: 24px;
            color: #fff;
            p {
                margin-right: 38px;
            }
        }
        img {}
        span:nth-child(1) {
            margin-left: -100px;
        }
        span:nth-child(2) {
            margin-left: 0px;
        }
        span:nth-child(3) {
            margin-left: -100px;
        }
    }
}

@media only screen and (max-width: $breakpoint-large) {
    .advance-Exercise-guide .slider-img {
        object-fit: cover;
        width: 100%;
        max-height: 1280px !important;
        height: 1280px !important;
    }
    .guide-text .cente-text1 p {
        width: 100%;
        padding-left: 0;
    }
    .guide-text {
        top: 8%;
    }
    .guide-text .right-img span:nth-child(1) {
        margin-left: 0px;
    }
    .guide-text .right-img span:nth-child(3) {
        margin-left: 0px;
    }
    .guide-text {
        flex-wrap: wrap;
    }
}