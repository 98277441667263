// #btn-image {
//     display: flex;
//     border: 1px solid #bfbdbd;
//     border-radius: 30px;
//     padding: 5px;
//     align-items: center;
//     font-size: 12px;
//     width: 102px;
//     height: 30px;
//     cursor: pointer;
//     box-shadow: 0px 1px 1px 1px #d3d2d2;
//     margin: 0 auto;
//     margin-top: 12px;
// }
// #btn-image img {
//     border-radius: 50%;
//     width: 23px;
//     height: 23px;
//     padding: 2px;
//     border: 1px solid #bfbdbd;
// }
// #btn-image:focus {
//     outline: none;
// }
// #btn-image span {
//     font-size: 8px;
// }
// #btn-image p {
//     display: block;
//     line-height: normal;
//     margin-left: 3px !important;
//     text-align: left;
//     font-size: 11px;
//     margin: 0;
// }
.btn-image {
    img {
        width: 110px;
        margin: auto;

        @media screen and (max-width: 767px) {
            margin: auto;
        }
    }
}