// @import "../../../node_modules/yet-another-react-lightbox/dist/styles.css";

body {
    font-family: 'Roboto-Regular'!important;
    font-size: 14px!important;
    color: #797979;
    font-weight: 400;
    line-height: 1.6;
}


/*===== BASE =====*/

* {
    margin: 0;
    padding: 0;
}

*,
::before,
::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

ul {
    margin: 0!important;
    padding: 0!important;
    list-style: none!important;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

a:hover,
a:focus {
    color: #000000;
    text-decoration: none!important;
    outline: none;
}

.p-tb-35 {
    padding-top: 25px;
    padding-bottom: 25px;
}


/* Secondary Header CSS Start*/


/* Mobile menu css End */


/* banner section Start */

.banner {
    background: url("./assets/images/banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    //overflow-x: hidden;
}

.banner::before {
    background-color: #282727ad;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
}

.banner-content {
    color: #fff;
    padding: 22px 0;
    z-index: 9;
    text-align: center;
    h1 {
        font-weight: 400;
        letter-spacing: 0.05em;
        color: #fff;
        margin: 0;
    }
}

.border-lefts {
    border-left: 1px solid #f4f4f4;
}