// Heebo fonts
@font-face {
    font-family: 'Heebo-Bold';
    src: url(../../assets/fonts/heebo/Heebo-Bold.ttf);
}

@font-face {
    font-family: 'Heebo-Black';
    src: url(../../assets/fonts/heebo/Heebo-Black.ttf);
}

@font-face {
    font-family: 'Heebo-Regular';
    src: url(../../assets/fonts/heebo/Heebo-Regular.ttf);
}

@font-face {
    font-family: 'Heebo-Medium';
    src: url(../../assets/fonts/heebo/Heebo-Medium.ttf);
}

@font-face {
    font-family: 'Heebo-ExtraBold';
    src: url(../../assets/fonts/heebo/Heebo-ExtraBold.ttf);
}

// georgia 
@font-face {
    font-family: 'georgiab';
    src: url(../../assets/fonts/georgia/georgiab.ttf);
}

@font-face {
    font-family: 'georgiai';
    src: url(../../assets/fonts/georgia/georgiai.ttf);
}

@font-face {
    font-family: 'georgia';
    src: url(../../assets/fonts/georgia/georgia.ttf);
}

@font-face {
    font-family: 'georgiz';
    src: url(../../assets/fonts/georgia/georgia.ttf);
}

// Lato
@font-face {
    font-family: 'Lato-Black';
    src: url(../../assets/fonts/lato/Lato-Black.ttf);
}

@font-face {
    font-family: 'Lato-Bold';
    src: url(../../assets/fonts/lato/Lato-Bold.ttf);
}

@font-face {
    font-family: 'Lato-Light';
    src: url(../../assets/fonts/lato/Lato-Light.ttf);
}

@font-face {
    font-family: 'Lato-Regular';
    src: url(../../assets/fonts/lato/Lato-Regular.ttf);
}

@font-face {
    font-family: 'Lato-Italic';
    src: url(../../assets/fonts/lato/Lato-Italic.ttf);
}

// Roboto
@font-face {
    font-family: 'Roboto-Black';
    src: url(../../assets/fonts/roboto/Roboto-Black.ttf);
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(../../assets/fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(../../assets/fonts/roboto/Roboto-Medium.ttf);
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(../../assets/fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url(../../assets/fonts/roboto/Roboto-Italic.ttf);
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(../../assets/fonts/roboto/Roboto-Light.ttf);
}

// Montserrat
@font-face {
    font-family: 'Montserrat-Medium';
    src: url(../../assets/fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url(../../assets/fonts/Montserrat/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'Montserrat-Medium-Italic';
    src: url(../../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf);
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url(../../assets/fonts/Montserrat/Montserrat-Bold.ttf);
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf);
}

// Segoe UI
@font-face {
    font-family: 'Segoe Regular';
    src: url(../../assets/fonts/Segoe/Segoe-UI.ttf);
}

@font-face {
    font-family: 'Segoe Bold';
    src: url(../../assets/fonts/Segoe/Segoe-UI-Bold.ttf);
}

//BAHNSCHRIFT FONTS
@font-face {
    font-family: 'BAHNSCHRIFT';
    src: url(../../assets/fonts/Bahnschrift/BAHNSCHRIFT.TTF);
}

@font-face {
    font-family: 'BAHNSCHRIFT 1';
    src: url(../../assets/fonts/Bahnschrift/BAHNSCHRIFT-1.TTF);
}

@font-face {
    font-family: 'BAHNSCHRIFT 2';
    src: url(../../assets/fonts/Bahnschrift/BAHNSCHRIFT-2.TTF);
}

// Arial 
@font-face {
    font-family: 'ARIAL';
    src: url(../../assets/fonts/arial/ARIAL.TTF);
}

// Avenir 
@font-face {
    font-family: 'Avenir Heavy';
    src: url(../../assets/fonts/Avenir/AvenirLTStd-Book.otf);
}

@font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/AvenirLTStd-Roman.otf);
}

// Helvetica-Font
@font-face {
    font-family: 'Helvetica-Bold';
    src: url(../../assets/fonts/Helvetica/Helvetica-Bold.ttf);
}

@font-face {
    font-family: 'Helvetica-Regular';
    src: url(../../assets/fonts/Helvetica/Helvetica.ttf);
}

@font-face {
    font-family: 'Helvetica-Bold';
    src: url(../../assets/fonts/Helvetica/Helvetica-Bold.ttf);
}

@font-face {
    font-family: 'Coco-Bold';
    src: url(../../assets/fonts/coco-gothic/CocoGothic-Bold_trial.ttf);
}

@font-face {
    font-family: 'Inter-Regular';
    src: url(../../assets/fonts/Inter/static/Inter-Regular.ttf);
}

@font-face {
    font-family: 'Inter-Bold';
    src: url(../../assets/fonts/Inter/static/Inter-Bold.ttf);
}

@font-face {
    font-family: 'Inter-Medium';
    src: url(../../assets/fonts/Inter/static/Inter-Medium.ttf);
}

@font-face {
    font-family: 'Inter-Light';
    src: url(../../assets/fonts/Inter/static/Inter-Light.ttf);
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url(../../assets/fonts/Inter/static/Inter-SemiBold.ttf);
}

@font-face {
    font-family: 'Calibri-Bold';
    src: url(../../assets/fonts/calibri-font/Calibri-Bold.TTF);
}

@font-face {
    font-family: 'Calibri-Regular';
    src: url(../../assets/fonts/calibri-font/Calibri-Regular.ttf);
}

@font-face {
    font-family: 'Calibri-Light';
    src: url(../../assets/fonts/calibri-font/Calibri-Light.ttf);
}