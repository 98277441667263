.gallery-section {
    .row {
        margin-right: -7px;
        margin-left: -7px;
    }
    padding: 35px 50px;
    .gallery-grid {
        padding-right: 7px;
        padding-left: 7px;
    }
    p {
        font-size: 15px;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        font-family: 'georgiai';
    }
    h3 {
        font-size: 24px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 15px;
        font-family: 'Heebo-Medium';
    }
    .thumbnail {
        margin-top: 14px;
        position: relative;
    }
    .exp-dec {
        font-style: italic;
        color: #bfbfbf;
        line-height: 1.1;
        margin-bottom: 15px
    }
    .thumbnail {
        .caption {
            position: absolute;
            padding: 17px;
            width: 100%;
            opacity: .9;
            margin: 0;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            p {
                position: absolute;
                top: 25%;
                left: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                color: #fff;
                font-size: 18px;
                line-height: 18px;
                text-transform: uppercase;
                letter-spacing: 0.2em;
                font-family: 'Heebo-ExtraBold';
            }
        }
    }
    .col-md-6:nth-child(1) .caption {
        background-color: #72b325;
    }
    .col-md-6:nth-child(2) .caption {
        background-color: #365edfe6;
    }
    .col-md-6:nth-child(3) .caption {
        background-color: #ed83c5;
    }
    .col-md-6:nth-child(4) .caption {
        background-color: #c4a731;
    }
}