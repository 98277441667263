    .trackimage {
        width: 542px;
    }
    
    .store-scanning-wrapper {
        width: 100%;
        overflow: hidden;
        .banner-scanning {
            position: relative;
            img {
                width: 100%;
                height: 840px;
            }
            &::before {
                background-color: #1d1c1cc7;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
            }
            .content-sec {
                position: absolute;
                width: 80%;
                top: 148px;
                z-index: 9;
                display: flex;
                justify-content: space-between;
                align-self: start;
                left: 0;
                right: 0;
                flex-wrap: wrap;
                margin: 0 auto;
                .content-left {
                    color: #fff;
                    padding-left: 50px;
                    h1 {
                        font-family: 'Montserrat-Bold';
                        font-size: 28px;
                        margin-bottom: 40px;
                    }
                    p:nth-child(1) {
                        font-size: 16px;
                        font-family: 'Montserrat-Medium';
                        opacity: .5;
                    }
                }
                .socil-img {
                    color: #C5D003;
                    i {
                        padding: 0 10px;
                        font-size: 20px;
                    }
                }
            }
            .img-content {
                position: absolute;
                right: 0px;
                left: 245px;
                top: 300px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .skinNewobs {
                    position: relative;
                    img {
                        width: 280px;
                        height: auto;
                    }
                    h4 {
                        bottom: 28px;
                        right: 22px;
                        position: absolute;
                        color: #C5D003;
                        font-family: 'Lato-Bold';
                        font-size: 18px;
                    }
                }
                .skin-banner-txt {
                    padding-left: 50px;
                    width: 45%;
                    z-index: 1;
                    h1 {
                        font-family: 'Lato-Bold';
                        font-size: 40px;
                        margin-bottom: 20px;
                        color: #C5D003;
                    }
                    h4 {
                        font-family: 'Lato-Bold';
                        font-size: 25px;
                        margin-bottom: 20px;
                        color: #fff;
                    }
                    p {
                        font-family: 'Lato-Regular';
                        font-size: 16px;
                        padding: 5px 20px 5px 0;
                        color: #fff;
                    }
                    .link-img {
                        text-align: center;
                        margin-top: 20px;
                        a {
                            cursor: pointer;
                            color: #fff;
                            margin-right: 5px;
                            i {
                                margin-right: 4px;
                            }
                        }
                    }
                }
                .scannNewsRun {
                    position: absolute;
                    right: 80px;
                    bottom: -56px;
                    img {
                        width: auto;
                        height: 700px;
                    }
                }
            }
        }
        .running-scanning {
            position: relative;
            margin: 60px 0 0 0;
            img {
                // height: 620px;
                width: 100%;
            }
            &::before {
                background-color: #1d1c1cc7;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
            }
            .scanning-sec {
                position: absolute;
                padding: 0 150px;
                .running-text {
                    color: #fff;
                    padding: 65px 0;
                    h4 {
                        font-family: 'ARIAL';
                        font-size: 25px;
                        font-weight: bold;
                    }
                    p {
                        font-family: 'Lato-Regular';
                        font-size: 15px;
                    }
                    P:last-child {
                        padding: 20px 0 0 5px;
                    }
                    li {
                        display: flex;
                        padding: 4px 10px;
                        p {
                            font-family: 'Lato-Bold';
                            font-size: 18px;
                            margin: 0;
                        }
                        span {
                            font-family: 'Lato-Regular';
                            font-size: 14px;
                        }
                        img {
                            height: 20px;
                            width: auto;
                            margin-right: 10px;
                            margin-top: 5px;
                        }
                    }
                }
                .runnig-img {
                    img {
                        width: 100%;
                        margin-top: -50px;
                    }
                }
            }
        }
        .scanning-info {
            padding: 50px 0;
            position: relative;
            img {
                position: absolute;
                left: -24px;
                height: 710px;
                top: -130px;
            }
            .scanning-text {
                width: 65%;
                margin: 0 auto;
                background-color: #F6F6F6;
                padding: 40px;
                h4 {
                    font-family: 'Avenir';
                    font-weight: bold;
                    font-size: 20px;
                    color: #8C8C8C;
                    text-align: center;
                    margin-bottom: 15px;
                }
                p {
                    font-family: 'Lato-Regular';
                    font-size: 14px;
                    color: #595959;
                }
            }
        }
        .scann-data {
            background-color: #9aa20009;
            padding: 30px 50px;
            .scanning-data {
                h4 {
                    font-family: 'Avenir';
                    font-weight: normal;
                    font-size: 25px;
                    color: #8C8C8C;
                    text-align: center;
                    margin: 40px;
                }
                ul {
                    li {
                        font-family: 'Helvetica-Regular';
                        font-size: 14px;
                        color: #595959;
                        padding-bottom: 20px;
                        padding-left: 100px;
                    }
                }
            }
            .scanning-data-img {
                img {
                    height: 560px;
                    margin-top: -25px;
                }
            }
        }
        .scan-gallery-img {
            position: relative;
            min-height: 380px;
            &::before {
                background-color: #1d1c1cc7;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
            }
            h3 {
                font-family: 'BAHNSCHRIFT';
                font-size: 24px;
                font-weight: bold;
                text-align: center;
                position: absolute;
                top: 30%;
                color: #fff;
                width: 80%;
                left: 0;
                margin: 0 auto;
                right: 0;
            }
        }
        .scan-book-now {
            padding: 20px 0 0px;
            h3 {
                font-family: 'Roboto-Bold';
                color: #9AA200;
                font-size: 30px;
                text-align: center;
                padding: 50px 0 40px;
            }
            button {
                font-size: 20px !important;
                color: #fff;
                display: flex;
                align-items: center;
                text-align: center;
                background: #9AA200;
                border: 0;
                outline: 0;
                padding: 22px 40px;
                border-radius: 4px;
                line-height: 17px;
                margin: 45px auto 0;
                img {
                    width: 25px;
                    margin-right: 10px;
                }
            }
            .circle-section {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                width: 65%;
                margin: 0 auto;
                .scan-book {
                    position: relative;
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    overflow: hidden;
                    .top-circle {
                        width: 90px;
                        overflow: hidden;
                        height: 75px;
                        border-radius: 50%;
                        top: -32px;
                        left: 0;
                        right: 0;
                        position: absolute;
                        margin: 0 auto;
                        h3 {
                            padding: 39px 10px 0;
                            text-align: center;
                            font-size: 20px;
                            font-family: 'BAHNSCHRIFT';
                            color: #fff;
                        }
                    }
                    p {
                        font-family: 'Lato-Regular';
                        padding: 56px 28px 0;
                        text-align: center;
                        font-size: 14px;
                    }
                }
                .step1 {
                    background-color: #7E489C;
                }
                .step2 {
                    background-color: #9AA200;
                }
                .step3 {
                    background-color: #659BBF;
                }
                .circle-color1 {
                    background-color: #E6C7F7;
                }
                .circle-color2 {
                    background-color: #E4E893;
                }
                .circle-color3 {
                    background-color: #C0DAEB;
                }
            }
        }
        .sacnning-head {
            background-color: #F6F6F6;
            padding: 30px 125px;
            margin-top: 60px;
            h1 {
                font-family: 'Avenir Heavy';
                font-size: 30px;
                color: #8C8C8C;
            }
            .con-profile {
                margin: 20px 0px;
                .con-pro {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    h2 {
                        font-family: 'Helvetica-Bold';
                        font-size: 20px;
                        margin: 0;
                    }
                }
                p {
                    font-family: 'Helvetica-Regular';
                    font-size: 16px;
                    color: #202124;
                }
            }
            .con-img {
                position: relative;
                img:nth-child(2) {
                    position: absolute;
                    top: 40%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
        .finds-store {
            padding: 50px 70px;
            h4 {
                font-family: 'Helvetica-Bold';
                font-size: 23px;
                color: #9AA200;
                margin-bottom: 30px;
            }
            .store-btn {
                display: flex;
                justify-content: center;
                margin: 15px 0px 0;
                button {
                    border: 2px solid #9AA200;
                    background: none;
                    padding: 5px 24px;
                    border-radius: 5px;
                    color: #9AA200;
                    font-size: 20px;
                    font-family: 'ARIAL';
                    outline: none;
                    cursor: pointer;
                }
            }
        }
        .finds-text {
            background-color: #9AA200;
            padding: 40px 0;
            h3 {
                padding: 0 160px 0px;
                font-family: 'Montserrat-Bold';
                font-size: 28px;
                text-align: center;
                color: #fff;
            }
        }
    }
    
    @media only screen and (max-width: 1030px) {
        .store-scanning-wrapper {
            .banner-scanning .img-content {
                left: 10px;
            }
            .running-scanning {
                img {
                    width: 100%;
                    height: 670px;
                }
                .scanning-sec .runnig-img img {
                    height: 400px;
                }
            }
        }
        .store-scanning-wrapper .running-scanning .scanning-sec {
            position: absolute;
            padding: 0px 62px;
        }
        .store-scanning-wrapper .scann-data .scanning-data ul li {
            padding-left: 1px;
        }
        .store-scanning-wrapper .banner-scanning .content-sec {
            position: absolute;
            width: 80%;
            top: 100px;
            z-index: 9;
            display: grid;
            justify-content: center;
            margin: 0 auto;
            text-align: center;
        }
    }
    
    @media only screen and (max-width: 768px) {
        .store-scanning-wrapper {
            .banner-scanning {
                img {
                    height: 678px;
                    width: 100%;
                }
                .content-sec {
                    width: 88%;
                    .content-left {
                        padding-left: 6px;
                        order: 1;
                    }
                    .socil-img {
                        order: 2;
                    }
                }
                .img-content {
                    left: 8px;
                    top: 155px;
                    .skinNewobs img {
                        width: 150px;
                        height: auto;
                    }
                    .skin-banner-txt {
                        width: 100%;
                        display: block;
                        padding-left: 0px;
                        z-index: 9;
                        h1 {
                            font-size: 25px;
                        }
                        h4 {
                            font-size: 18px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                    .scannNewsRun {
                        right: 0px;
                        bottom: 90px;
                        img {
                            width: auto;
                            height: 455px;
                        }
                    }
                }
            }
            .scanning-data-img {
                display: flex;
                justify-content: center;
            }
            .running-scanning {
                .scanning-sec {
                    position: absolute;
                    padding: 0 15px;
                    .runnig-img img {
                        width: 100%;
                        margin-top: -50px;
                        height: auto;
                    }
                }
                img {
                    width: 100%;
                    height: 830px;
                }
            }
            .scanning-info {
                .scanning-text {
                    width: 100%;
                    margin: 0 auto;
                    background-color: #F6F6F6;
                    padding: 40px;
                }
                img {
                    position: relative;
                    left: -33px;
                    height: 687px;
                    top: 97px;
                }
            }
            .scann-data {
                padding: 30px 15px;
                .scanning-data {
                    ul {
                        li {
                            padding-left: 15px;
                        }
                    }
                }
            }
            .scan-book-now .circle-section .scan-book {
                margin: 15px 0;
            }
            .sacnning-head {
                padding: 30px 15px;
            }
            .finds-store {
                padding: 15px 15px;
            }
            .finds-text h3 {
                padding: 0 15px 0px;
            }
            .scan-gallery-img {
                margin: 10px 10px;
                img {
                    width: 100%;
                }
            }
        }
    }
    
    @media only screen and (max-width: 468px) {
        .store-scanning-wrapper {
            .banner-scanning {
                img {
                    height: 727px;
                    width: 100%;
                }
                .content-sec {
                    width: 88%;
                    .content-left {
                        padding-left: 6px;
                        order: 2;
                    }
                    .socil-img {
                        order: 1;
                    }
                }
                .img-content {
                    left: 8px;
                    top: 155px;
                    .skinNewobs img {
                        width: 150px;
                        height: auto;
                        margin-top: 65px
                    }
                    .skin-banner-txt {
                        width: 100%;
                        display: block;
                        padding-left: 0px;
                        z-index: 9;
                        h1 {
                            font-size: 25px;
                        }
                        h4 {
                            font-size: 18px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                    .scannNewsRun {
                        right: 0px;
                        bottom: 180px;
                        img {
                            width: auto;
                            height: 395px;
                        }
                    }
                }
            }
            .running-scanning {
                .scanning-sec {
                    position: absolute;
                    padding: 0 15px;
                    .runnig-img img {
                        width: 100%;
                        margin-top: -50px;
                        height: 392px;
                    }
                }
                img {
                    width: 100%;
                    height: 830px;
                }
            }
            .scanning-info {
                .scanning-text {
                    width: 100%;
                    margin: 0 auto;
                    background-color: #F6F6F6;
                    padding: 40px;
                }
                img {
                    position: relative;
                    left: -33px;
                    height: 687px;
                    top: 97px;
                }
            }
            .scann-data {
                padding: 30px 15px;
                .scanning-data {
                    ul {
                        li {
                            padding-left: 15px;
                        }
                    }
                }
            }
            .scan-book-now .circle-section .scan-book {
                margin: 15px 0;
            }
            .sacnning-head {
                padding: 30px 15px;
            }
            .finds-store {
                padding: 15px 15px;
            }
            .finds-text h3 {
                padding: 0 15px 0px;
            }
        }
    }