.advance-Exercise {
    width: 100%;
    overflow: hidden;

    .info-wraper {
        background-color: #eff8e5;
        margin-top: -6px;
        padding: 10px 180px;

        .info-sec {
            display: flex;
            align-items: center;
            padding: 10px;
            margin: 5px 15px;
            background: #def2cd;
            border-radius: 45px 0 0 0;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            p {
                margin: 0;
            }

            .info-left {
                display: flex;
                align-items: center;
                margin-left: 15px;

                p {
                    margin-left: 15px;
                }
            }

            p:nth-last-child(2) {
                position: relative;
            }

            p:nth-last-child(2)::before {
                content: '|';
                position: absolute;
                left: -7px;
                color: #55b700;
                font-weight: 600;
            }
        }

        .tb-info {
            display: flex;
            justify-content: right;
            align-items: center;
            margin-bottom: 5px;

            span {
                font-family: 'Lato-Bold';
                font-size: 16px;
                margin-right: 10px;
                width: 100px;
                display: block;
                text-transform: uppercase;
                text-align: right;
            }

            img {
                width: 50px;
            }
        }

        .activeimg {
            background-image: url(../../assets/images/personHealth.png);
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            background-size: 50px 50px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 50px;
            }
        }

        .app-btn {
            display: flex;
            flex-wrap: wrap;

            button {
                // background-image: linear-gradient(#fbfbfb, #b9b9b7);
                // border: 0;
                // border-radius: 15px;
                // padding: 5px 15px;
                // margin-right: 10px;
                // display: flex;
                // align-items: center;
                // outline: none;
                border: 1px solid #c8c9c6;
                border-radius: 6px;
                padding: 2px 10px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
                background: #def2cd;
                color: #62B01E;
                font-weight: 600;
                margin-top: 8px;

                img {
                    width: 15px;
                    margin-right: 5px;
                }
            }
        }

        @media only screen and (max-width: 1400px) {
            .info-sec {
                justify-content: center;

                p:nth-last-child(2) {
                    text-align: center;
                }

                p:nth-last-child(2)::before {
                    content: '';
                    position: absolute;
                    left: -7px;
                    color: unset;
                    font-weight: unset;
                }
            }

            .tb-info {
                position: relative;
                top: 25%;
            }
        }
    }

    .advance-Exercise-slider {
        overflow: hidden;
    }

    .slider-img {
        min-height: 600px;
        object-fit: cover;
        width: 100%;
        @media only screen and (max-width: 767px) {
            object-position: -650px;
          }
          @media only screen and (min-width: 767px) and (max-width: 991.98px) {
            object-position: -330px;
          }
    }

    .slider-conatiner {
        position: relative;

        .cente-text {
            position: absolute;
            top: 33%;
            left: 0;
            right: 0;
            width: 80%;
            margin: 0 auto;
            text-align: center;

            h1 {
                font-family: 'Montserrat-Medium';
                font-size: 56px;
                color: #fff;
                text-transform: uppercase;
            }

            p {
                font-family: 'Montserrat-Medium';
                font-size: 30px;
                color: #fff;
                padding: 12px 0px;
                text-transform: uppercase;
                max-width: 800px;
                margin: auto;
            }

            button {
                background-color: transparent;
                border-radius: 10px;
                border: 3px solid #fff;
                font-family: 'Montserrat-Medium';
                font-size: 32px;
                padding: 10px 80px;
                color: #fff;
                text-transform: uppercase;
                cursor: pointer;
                margin-top: 30px;
            }
        }

        .arrow-icon {
            background-image: url(../../assets/images/down-arrow-back.png);
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            background-size: 50px 50px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8%;
            margin-bottom: 10px;
            cursor: pointer;

            img {
                width: 25px;
                height: 14px;
                margin-top: 5px;
            }
        }

        .right-icon {
            position: absolute;
            top: 37%;
            bottom: 0;
            right: 3%;

            .tb-info {
                display: flex;
                justify-content: right;
                align-items: center;
                margin-bottom: 5px;

                span {
                    font-family: 'Lato-Bold';
                    font-size: 16px;
                    margin-right: 10px;
                    width: 100px;
                    display: block;
                    text-transform: uppercase;
                    text-align: right;
                }

                img {
                    width: 70px;
                }

                .health-co {
                    color: #51be58;
                }

                .pro-co {
                    color: #fc00fe;
                }

                .ins-co {
                    color: #fec604;
                }

                .joy-co {
                    color: #0491fe;
                }
            }

            .activeimg {
                background-image: url(../../assets/images/personHealth.png);
                background-repeat: no-repeat;
                width: 72px;
                height: 72px;
                background-size: 70px 72px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 70px;
                }
            }
        }

        &::before {
            background-color: #2827274d;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }
}

.weight-shifter-plan {
    h1 {
        font-family: 'Lato-Bold';
        font-size: 22px;
        color: #8DD54E;
        margin-bottom: 20px;
        margin-left: 70px;
        cursor: pointer;
    }
}

.advance-Exercise {
    .botom-icon {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 25px 0;
        flex-wrap: wrap;

        @media screen and (max-width: 740px) {
            display: none;
        }

        .setion-name {
            display: flex;
            align-items: center;

            img {
                width: 25px;
                height: 25px;
                margin-right: 15px;
            }

            p {
                font-family: 'Montserrat-Regular';
                font-size: 24px;
                margin: 0;
            }
        }

        span {
            font-size: 25px;
            margin-right: 30px;
            margin-left: 30px;
            color: #cdcdcd;
        }
    }
}

.advance-Exercise {
    .exercise-secion-right {
        background-image: linear-gradient(to right, #b6b6b6 28%, #fff 50%);
        margin-top: -8px;

        .exe-inner-sp {
            padding: 35px;
        }

        .section-right {
            width: 80%;
            margin-left: 20%;
        }

        h1 {
            text-align: center;
            font-family: 'Lato-Bold';
            font-size: 55px;
            color: #8c8c8c;
            padding: 30px 8px;
            text-transform: uppercase;
        }

        h2 {
            text-align: center;
            font-family: 'Lato-Regular';
            font-size: 55px;
            color: #8c8c8c;
            padding: 40px 8px;
            opacity: .2;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .exe-inner {
            background-repeat: no-repeat;
            border-radius: 40px 0 0 40px;
            position: relative;
            background-position: center;
        }

        .exe-inner::before {
            background-color: #282727ad;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
            border-radius: 40px 0 0 40px;
        }

        .line-inner {
            display: flex;
            align-items: center;
            margin-left: 30px;

            @media screen and (max-width: 1100px) {
                justify-content: center;
                margin-top: 31px;
            }
        }

        .v-line {
            width: 3px;
            background-color: #fff;
            border-radius: 5px;
            min-height: 190px;
            margin-right: 15px;
            margin-top: 20px;
            margin-left: 20px;
        }

        .v-line5,
        .v-line3 {
            min-height: 221px;
            margin-top: 0px;
        }

        .v-line1 {
            min-height: 170px;
            margin-top: 0px;
        }

        .line-inner1 {
            margin-top: 40px;
        }

        .line-inner3 {
            margin-top: 16px;
        }

        .v-text {
            font-family: 'Lato-Regular';
            font-size: 18px;
            color: #fff;
            border-left: 4px solid white;
            padding-left: 15px !important;

            li {
                padding: 16px 12px;
                text-transform: uppercase;
                font-size: 34px;

                @media screen and (max-width: 1100px) {
                    font-size: 28px;
                }
            }
        }

        .content-inner {
            h3 {
                font-family: 'Lato-Bold';
                font-size: 34px !important;
                color: #0ddb1a;
                margin-bottom: 5px;
            }

            p {
                font-family: 'Lato-Regular';
                font-size: 14px;
                color: #fff
            }

            @media screen and (max-width: 1100px) {
                text-align: center;
                margin: 0 20px;
                margin-top: 20px;
            }
        }

        .btn-section {
            display: flex;
            align-items: center;
            margin-top: 30px;
            gap: 20px;

            @media screen and (max-width: 1100px) {
                justify-content: center;
            }

            button {
                border: none;
                border-radius: 30px;
                padding: 8px 40px;
                background: linear-gradient(#FBFBFB, #BBBBBB);
                font-size: 16px;
                font-family: 'Lato-Regular';
            }

            img {
                width: 30px;
                margin-left: 10px;
            }
        }
    }
}

.advance-Exercise {
    .exercise-secion-left {
        background-image: linear-gradient(to right, #fff 28%, #ecffdc 50%);
        margin-top: -8px;

        .exe-inner-sp {
            padding: 25px;
        }

        .section-right {
            width: 80%;
        }

        h1 {
            text-align: center;
            font-family: 'Lato-Bold';
            font-size: 55px;
            color: #8C8C8C;
            padding: 30px 8px;
            text-transform: uppercase;
        }

        h2 {
            text-align: center;
            font-family: 'Lato-Regular';
            font-size: 55px;
            color: #8c8c8c;
            opacity: .2;
            padding: 30px 0;
            text-transform: uppercase;
        }

        .exe-inner {
            background-repeat: no-repeat;
            border-radius: 0px 20px 20px 0px;
            position: relative;
            background-position: center;
        }

        .exe-inner::before {
            background-color: #282727ad;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
            border-radius: 0px 20px 20px 0px;
        }

        .line-inner {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-right: 30px;

            @media screen and (max-width: 1100px) {
                justify-content: center;
            }
        }

        .line-4 {
            margin-top: 30px;
        }

        .v-line {
            width: 3px;
            background-color: #fff;
            border-radius: 5px;
            min-height: 190px;
            margin-right: 15px;
            margin-top: 20px;
            margin-left: 20px;
        }

        .line-inner {
            margin-top: 31px
        }

        .v-line4,
        .v-line2 {
            min-height: 170px;
            margin-top: 0px;
        }

        .v-line5 {
            min-height: 220px;
            margin-top: 0px;
        }

        .v-text {
            font-family: 'Lato-Regular';
            font-size: 18px;
            color: #fff;
            border-right: 4px solid white;
            padding-right: 15px !important;

            li {
                padding: 16px 12px;
                text-transform: uppercase;
                font-size: 34px;

                @media screen and (max-width: 1100px) {
                    font-size: 28px;
                }
            }
        }

        .content-inner {
            h3 {
                font-family: 'Lato-Bold';
                font-size: 34px !important;
                color: #0ddb1a;
                margin-bottom: 5px;
            }

            p {
                font-family: 'Lato-Regular';
                font-size: 14px;
                color: #fff
            }

            @media screen and (max-width: 1100px) {
                text-align: center;
                margin: 0 20px;
                margin-top: 20px;
            }
        }

        .btn-section {
            display: flex;
            align-items: center;
            margin-top: 30px;
            gap: 20px;

            @media screen and (max-width: 1100px) {
                justify-content: center;
            }

            button {
                border: none;
                border-radius: 30px;
                padding: 8px 40px;
                background: linear-gradient(#FBFBFB, #BBBBBB);
                font-size: 16px;
                font-family: 'Lato-Regular';
            }

            img {
                width: 30px;
                margin-left: 10px;
            }
        }
    }
}

.exe-bg1 {
    background-image: url(../../assets/images/advance-exercises/fu-exercise.png);
    background-size: cover;
    min-height: 328px;
}

.exe-bg2 {
    background-image: url(../../assets/images/advance-exercises/home-workout.png);
    background-size: cover;
    min-height: 328px;
}

.exe-bg3 {
    background-image: url(../../assets/images/advance-exercises/yoga.png);
    background-size: cover;
    min-height: 328px;
}

.exe-bg4 {
    background-image: url(../../assets/images/advance-exercises/dance.png);
    background-size: cover;
    min-height: 328px;
}

.exe-bg5 {
    background-image: url(../../assets/images/advance-exercises/mind-health.png);
    background-size: cover;
}

.shifter-plan {
    margin: 10px 0 0;
    position: fixed;
    bottom: 0;
    transition: 1s;
    z-index: 9;
    padding: 20px 60px 0 60px;
    width: 100%;
    background: #fff;

    h3 {
        color: #8dd54f;
        font-size: 18px;
    }

    p {
        text-align: center;
        margin: 10px 0;
    }

    .ex-plans {
        select {
            border: 2px solid #8dd54f;
        }

        input[type=text] {
            border: 2px solid #8dd54f;
        }

        .submit-btn {
            width: 100%;
            display: flex;
            justify-content: center;

            button {
                color: #fff;
                background: #8dd54f;
                padding: 5px 80px;
                margin: 20px auto 10px;
            }
        }
    }
}

.showForm {
    bottom: 0px;
    transition: 1s;
}

.hideForm {
    bottom: -400px;
    transition: 1s;
}

.advance-Exercise-slider {
    .slick-dots {
        position: absolute;
        bottom: 0;
        display: grid !important;
        width: 100%;
        right: 50px;
        padding: 10;
        justify-content: end;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: bottom .3s;
        list-style: none;
        text-align: center;
        transition: bottom 300ms;

        .slick-active {
            background-image: url(../../assets/images/personHealth.png);
            background-position: top;
            background-position: bottom;
            background-position: left;
            background-position: right;
            background-position: center;
            background-size: 71px 71px;
        }
    }

    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 70px;
        margin: 10px;
        padding: 0;
        cursor: pointer;
    }

    .slick-dots li.slick-active button:before {
        font-size: 15px;
        color: blue;
    }

    .slick-dots[data-current-slide="3"] {
        bottom: -20px;
    }

    .hide-text {
        display: none;
    }

    .slick-active {
        .tb-info {
            display: flex;
            justify-content: right;
            align-items: center;
            margin-bottom: 5px;

            span {
                font-family: 'Lato-Bold';
                font-size: 16px;
                margin-right: 10px;
                width: 100px;
                display: block;
                text-transform: uppercase;
                text-align: right;

                .hide-text {
                    display: block;
                }
            }

            img {
                width: 70px;
            }

            .dot-text1 {
                color: #51be58;
            }

            .dot-text3 {
                color: #fc00fe;
            }

            .dot-text4 {
                color: #fec604;
            }

            .dot-text2 {
                color: #0491fe;
            }
        }
    }
}

@media (min-width:1700px) {
    .advance-Exercise .exercise-secion-left .content-inner p {
        font-size: 22px;
    }

    .advance-Exercise .exercise-secion-left .content-inner h3 {
        font-size: 28px;
    }

    .advance-Exercise .exercise-secion-left .line-inner {
        align-items: center;
    }

    .advance-Exercise .exercise-secion-left .v-text {
        font-size: 30px;
    }

    .advance-Exercise .exercise-secion-right .content-inner p {
        font-size: 22px;
    }

    .advance-Exercise .exercise-secion-right .content-inner h3 {
        font-size: 28px;
    }

    .advance-Exercise .exercise-secion-right .line-inner {
        align-items: center;
    }

    .advance-Exercise .exercise-secion-right .v-text {
        font-size: 30px;
    }

    .advance-Exercise .exercise-secion-right .exe-inner {
        height: 500px;
    }

    .advance-Exercise .exercise-secion-left .exe-inner {
        height: 500px;
    }

    .advance-Exercise .exercise-secion-right .v-line {
        min-height: 308px;
    }

    .advance-Exercise .exercise-secion-left .v-line {
        min-height: 308px;
    }
}

@media (max-width:992px) {
    .advance-Exercise.page-w .slider-conatiner .cente-text {
        top: 50%;
        transform: translateY(-50%);
    }
}

@media (min-width:768px) and (max-width:992px) {}

@media (max-width:768px) {
    .advance-Exercise.page-w .right-icon {
        display: none;
    }

    .advance-Exercise .tb-info .dotbtns {
        display: none;
    }

    .advance-Exercise .slick-dots li {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 4px;
        padding: 0;
        cursor: pointer;
    }

    .advance-Exercise .tb-info img {
        width: 40px;
    }

    .advance-Exercise .slick-active .tb-info img {
        width: 40px;
    }

    .advance-Exercise .slick-dots .slick-active {
        background-size: 41px 41px;
    }

    .advance-Exercise .slick-dots {
        display: flex !important;
        width: 100%;
        right: auto;
        padding: 10px;
        justify-content: center;
        margin: 0;
        bottom: 10px;
        top: 88%;
        transition: bottom 300ms;
        height: 55px;

        @media only screen and (max-width: 600px) {
            display: none !important;
        }
    }

    .advance-Exercise-slider .slick-active .tb-info span {
        text-align: center;
        position: absolute;
        top: -32px;
        left: -27px;
    }
}

@media (max-width:767px) {
    .advance-Exercise.page-w .exercise-secion-right .section-right {
        width: 100%;
        margin-left: 0%;
    }

    .advance-Exercise.page-w .exercise-secion-left .section-right {
        width: 100%;
    }
}

@media (max-width:767px) {
    .advance-Exercise.page-w .slider-conatiner .cente-text h1 {
        font-size: 30px;
    }

    .advance-Exercise.page-w .slider-conatiner .cente-text button {
        padding: 5px 20px;
    }

    .advance-Exercise.page-w .slider-conatiner .cente-text p {
        font-size: 22px;
    }

    .advance-Exercise.page-w .exercise-secion-right .section-right {
        width: 100%;
        margin-left: 0%;
    }

    .advance-Exercise.page-w .exercise-secion-left .section-right {
        width: 100%;
    }

    .advance-Exercise.page-w .exercise-secion-right h2,
    .advance-Exercise.page-w .exercise-secion-left h2,
    .advance-Exercise.page-w .exercise-secion-right h1,
    .advance-Exercise.page-w .exercise-secion-left h1 {
        font-size: 30px;
    }

    .exercise-secion-right .person-health-img,
    .exercise-secion-left .person-health-img {
        width: 100px;
        margin: 0 auto;
        
    }
}

@media (max-width:1400px) {
    .advance-Exercise.page-w .exercise-secion-right .section-right {
        width: 100%;
        margin-left: 0%;
    }

    .advance-Exercise.page-w .exercise-secion-left .section-right {
        width: 100%;
    }
}