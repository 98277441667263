.sig-card {
    position: relative;
    .playicon {
        position: absolute;
        width: 50px !important;
        height: 50px !important;
    }
    .title-icon {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 70%;
        min-height: 40px;
        p {
            margin: 0 !important;
            line-height: 14px;
            width: 70%;
            text-align: center;
            margin-left: 25px !important;
        }
        .img-s-b {
            width: 60px;
            display: flex;
            justify-content: space-between;
            img {
                width: 18px !important;
                height: 18px !important;
                border-radius: 0 !important;
            }
        }
    }
}