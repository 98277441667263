.r-banner-content {
    text-align: center;
    h1 {
        font-family: 'Montserrat-Medium';
        font-size: 38px;
        font-weight: 400;
        letter-spacing: 0.05em;
        color: #fff;
        margin: 0;
        padding: 45px 0;
        padding-top: 100px;
    }
}

.register-page {
    width: 100%;
    overflow: hidden;
}