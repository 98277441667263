.store-skin-wrapper {
    //overflow-x: hidden;
    width: 100%;
    overflow: hidden;
    .banner-skin {
        position: relative;
        img {
            width: 100%;
            height: 830px;
        }
        &::before {
            background-color: #2827278e;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
        .content-sec {
            position: absolute;
            width: 80%;
            top: 120px;
            display: flex;
            justify-content: space-between;
            align-self: start;
            left: 0;
            right: 0;
            margin: 0 auto;
            flex-wrap: wrap;
            .content-left {
                color: #fff;
                padding-left: 50px;
                h1 {
                    font-family: 'Montserrat-Bold';
                    font-size: 28px;
                    margin-bottom: 40px;
                }
                p:nth-child(1) {
                    font-size: 16px;
                    font-family: 'Montserrat-Medium';
                    opacity: .5;
                }
            }
            .socil-img {
                color: #C5D003;
                i {
                    padding: 0 10px;
                    font-size: 20px;
                }
            }
        }
        .img-content {
            position: absolute;
            right: 0px;
            // left: 184px;
            // top: 165px;
            left: 245px;
            top: 245px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .skinNewobs {
                position: relative;
                h4 {
                    bottom: 28px;
                    right: 22px;
                    position: absolute;
                    color: #C5D003;
                    font-family: 'Lato-Bold';
                    font-size: 18px;
                }
            }
            .skin-banner-txt {
                padding-left: 50px;
                width: 55%;
                h1 {
                    font-family: 'Lato-Bold';
                    font-size: 40px;
                    margin-bottom: 20px;
                    color: #C5D003;
                }
                h4 {
                    font-family: 'Lato-Bold';
                    font-size: 25px;
                    margin-bottom: 20px;
                    color: #fff;
                }
                p {
                    font-family: 'Lato-Regular';
                    font-size: 14px;
                    padding: 10px 50px 2px 0;
                    color: #fff;
                    margin-bottom: 0px;
                }
                .head-txt {
                    font-family: 'Lato-Bold';
                }
                .link-img {
                    text-align: center;
                    margin-top: 20px;
                    a {
                        cursor: pointer;
                        color: #fff;
                        margin-right: 5px;
                        i {
                            margin-right: 4px;
                        }
                    }
                }
            }
            img {
                width: 280px;
                height: 100%;
            }
        }
    }
    .point-section {
        margin: 20px 0;
        color: #8C8C8C;
        p {
            font-family: 'Lato-Regular';
            font-size: 18px;
        }
        .img-section {
            padding: 30px 0;
        }
        ul {
            li {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 12px;
                font-family: 'Lato-Regular';
                font-size: 16px;
                img {
                    margin-right: 8px;
                    margin-top: 5px;
                }
            }
        }
    }
    .back-point-skin {
        background-color: #9aa2001c;
        margin-bottom: 30px;
    }
    .link-cont-img {
        h1 {
            font-family: 'Roboto-Regular';
            color: #3C3C3C;
            font-size: 30px;
        }
        p {
            font-family: 'ARIAL';
            color: #8C8C8C;
            font-size: 14px;
        }
        img {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .link-skin {
        position: relative;
        .link-cont-text {
            color: #fff;
            padding: 0 62px;
            h1 {
                font-size: 40px;
                font-family: 'Lato-Bold';
            }
            p {
                font-family: 'Lato-Regular';
                font-size: 28px;
            }
        }
        .back-img {
            position: absolute;
            top: -208px;
            z-index: -1;
            height: 100%;
            width: 100%;
            min-height: 570px;
            z-index: -1;
        }
        .back-img::before {
            background-color: #7c7a7a8e;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }
    .con-head {
        background-color: #F6F6F6;
        padding: 30px 125px 60px 280px;
        margin-top: 130px;
        h1 {
            font-family: 'Avenir Heavy';
            font-size: 30px;
            color: #8C8C8C;
        }
        .con-profile {
            margin: 20px 0px;
            .con-pro {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                h2 {
                    font-family: 'Helvetica-Bold';
                    font-size: 20px;
                    margin: 0;
                }
            }
            p {
                font-family: 'Helvetica-Regular';
                font-size: 16px;
            }
        }
        .con-img {
            position: relative;
            img:nth-child(2) {
                position: absolute;
                top: 40%;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }
    .finds-store {
        padding: 50px 70px;
        h4 {
            font-family: 'Helvetica-Bold';
            font-size: 20px;
            color: #9AA200;
        }
        .store-btn {
            display: flex;
            justify-content: center;
            margin: 15px 0px 0;
            button {
                border: 2px solid #9AA200;
                background: none;
                padding: 5px 24px;
                border-radius: 5px;
                color: #9AA200;
                font-size: 17px;
                font-family: 'ARIAL';
                outline: none;
                cursor: pointer;
            }
        }
    }
    .finds-text {
        background-color: #9AA200;
        padding: 40px 0;
        h3 {
            padding: 0 160px 0px;
            font-family: 'Montserrat-Bold';
            font-size: 24px;
            text-align: center;
            color: #fff;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .store-skin-wrapper .con-head {
        background-color: #F6F6F6;
        padding: 30px 125px 60px 125px;
        margin-top: 130px;
    }
    .store-skin-wrapper .link-skin .link-cont-text p {
        font-family: 'Lato-Regular';
        font-size: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .store-skin-wrapper {
        .banner-skin {
            img {
                height: 990px;
                width: 100%;
            }
            .content-sec {
                width: 88%;
                justify-content: center;
                .content-left {
                    padding-left: 6px;
                    order: 1;
                }
                .socil-img {
                    order: 2;
                }
            }
            .img-content {
                left: 8px;
                top: 155px;
                margin: 0 30px;
                .skinNewobs img {
                    width: 150px;
                    height: auto;
                }
                .skin-banner-txt {
                    width: 100%;
                    display: block;
                    padding-left: 0px;
                    z-index: 9;
                    h1 {
                        font-size: 30px;
                    }
                    h4 {
                        font-size: 25px;
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
        .link-skin .back-img {
            top: -120px;
        }
        .con-head {
            padding: 19px 15px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .store-skin-wrapper {
        .banner-skin {
            img {
                height: 1060px;
                width: 100%;
            }
            .content-sec {
                width: 88%;
                justify-content: center;
                .content-left {
                    padding-left: 6px;
                    order: 1;
                }
                .socil-img {
                    order: 2;
                }
            }
            .img-content {
                left: 8px;
                top: 155px;
                .skinNewobs img {
                    width: 150px;
                    height: auto;
                }
                .skin-banner-txt {
                    width: 100%;
                    display: block;
                    padding-left: 0px;
                    z-index: 9;
                    h1 {
                        font-size: 28px;
                    }
                    h4 {
                        font-size: 22px;
                    }
                    p {
                        font-size: 18px;
                    }
                }
            }
        }
        .link-cont-img img {
            margin: 0 auto;
            margin-bottom: 10px;
        }
        .link-skin {
            .link-cont-text {
                padding: 50px 15px;
            }
            .back-img {
                top: 30px;
                height: 450px;
            }
        }
        .con-head {
            background-color: #F6F6F6;
            padding: 30px 15px;
            margin-top: 15px;
        }
    }
    .store-skin-wrapper .finds-text h3 {
        padding: 0 14px 0px;
        font-family: 'Montserrat-Bold';
        font-size: 24px;
        text-align: center;
        color: #fff;
    }
    .store-skin-wrapper .finds-store {
        padding: 50px 5px;
    }
}

@media only screen and (max-width: 568px) {
    .store-skin-wrapper {
        .banner-skin {
            img {
                height: 1040px;
                width: 100%;
            }
            .content-sec {
                width: 88%;
                justify-content: center;
                .content-left {
                    padding-left: 6px;
                    order: 2;
                }
                .socil-img {
                    order: 1;
                }
            }
            .img-content {
                left: 8px;
                top: 155px;
                .skinNewobs img {
                    width: 110px;
                    height: auto;
                    margin-top: 60px;
                }
                .skin-banner-txt {
                    width: 100%;
                    display: block;
                    padding-left: 0px;
                    z-index: 9;
                    h1 {
                        font-size: 25px;
                    }
                    h4 {
                        font-size: 18px;
                    }
                    p {
                        font-size: 14px;
                        padding: 5px 5px 2px 0;
                    }
                }
            }
        }
        .link-cont-img img {
            margin: 0 auto;
            margin-bottom: 10px;
        }
        .link-skin {
            .link-cont-text {
                padding: 50px 15px;
            }
            .back-img {
                top: 30px;
                height: 450px;
            }
        }
        .con-head {
            background-color: #F6F6F6;
            padding: 30px 15px;
            margin-top: 15px;
        }
    }
    .store-skin-wrapper .link-skin .link-cont-text p {
        font-size: 18px;
    }
}