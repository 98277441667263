.benefits-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 50px 130px;
    padding-right: unset;
    margin-top: 30px;
    
    &_points {
        z-index: 2;
        h1 {
            color: #595959;
            font-family: 'Montserrat-Medium';
            font-size: 48px;
            padding-left: 70px;
            padding-bottom: 20px;
        }
        .gal-con-text {
            background-color: #f2f2f295;
            font-family: 'Lato-light';
            font-size: 17px;
            padding: 40px;
            width: 820px;
            border-radius: 15px;
        }

        li {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 35px;
            margin-bottom: 10px;

            img {
                width: 40px;
                height: 40px;
            }

        }
    }

    &_img-container {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 750px;
        width: 1100px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100px);

        .fu-dis {
            width: 60%;

            .fu-head {
                background: #fff;
                margin: 0;
                text-align: center;
                h2 {
                    background: #fff;
                    border-bottom: 1px solid #46a1fb;
                    display: inline-block;
                    padding: 20px 0;
                    text-align: center;
                    margin: 0;
                    font-family: 'Montserrat-Medium';
                    font-size: 17px;
                }
            }
            .fu-dis-con {
                opacity: .8;
                background: #fff;
                .fu-dis-txt {
                    padding: 40px;
                    h4 {
                        font-family: 'Lato-Regular';
                        font-size: 20px;
                        text-transform: uppercase;
                    }
                    p {
                        font-family: 'Lato-Regular';
                        font-size: 17px;
                    }
                }
                .fu-dis-time {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 40px;
                    font-family: 'Lato-light';
                    font-size: 16px;
                    span {
                        font-family: 'Lato-regular';
                        font-size: 20px;
                    }
                }
                .fu-dis-btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 40px;
                    padding-bottom: 40px;
                    margin-bottom: 20px;

                    @media  only screen and (max-width: 600px) {
                        flex-direction: column;
                        gap: 10px;
                    }
                    
                    button {
                        border-radius: 43px;
                        padding: 2px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 20px;
                        width: 100%;
                        background: transparent;
                        border: 2px solid #898f95;
                        outline: none;
                        background-color: #fff;
                        font-family: 'Lato-Regular';
                        font-size: 16px;
                        span {
                            font-family: 'Lato-Regular';
                            font-size: 16px;
                        }
                    }
                    button:nth-child(2) {
                        margin: 0 10px;
                        background-color: #fff;
                    }
                    button:nth-child(3) {
                        background-color: #8C8C8C;
                        color: #fff;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1600px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        &_img-container {
            transform: unset;
        }
    }

    @media only screen and (max-width: 900px) {
        padding: 50px 10px;

        &_img-container {
            height: 600px;
            width: 95%;
        }

        &_points {
            .gal-con-text {
                font-size: 15px;
                padding: 30px 40px;
                width: 90%;
                margin: auto;
            }
        }
    }
}

.show {
    display: flex;
}

.hide {
    display: none;
}