.video-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; /* Full width of parent */
    height: 100%; /* Full height of parent */
    
    margin: auto;
    overflow: hidden;
    background: black; /* Background color to fill empty space */
  }
  
  .carousel-video {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .carousel-video iframe {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the container proportionally */
    aspect-ratio:9 / 16; /* Maintains a consistent aspect ratio */
    border: none;
  }
  
  .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  .carousel-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  