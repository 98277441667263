.plan-more-detail {
    background: #F5F5F5;
    h1 {
        font-family: 'Montserrat-Bold';
        font-size: 25px;
        margin: 0;
        color: #636363;
        text-align: center;
        padding: 20px 0
    }
}

.plan-detail1 {
    background-color: #DFDFDF;
    padding: 20px 80px;
    color: #585858;
    h1 {
        font-family: 'Lato-Bold';
        font-size: 20px;
    }
    p {
        font-family: 'Lato-Regular';
        font-size: 18px;
    }
}