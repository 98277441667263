.form-section h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.1rem;
    margin-bottom: 15px;
    font-family: 'Lato-Light';
}

.login-form {
    width: 340px;
    margin: 0 auto;
    padding: 20px 0px;
    label {
        display: inline-block;
        margin-bottom: .2rem;
        font-size: 14px;
        font-family: 'Roboto-Regular';
    }
}

.login-form .eye-password {
    position: relative;
    .input-group-addon {
        position: absolute;
        right: 9px;
        top: 9px;
        color: #797d86;
        a {
            color: #797d86;
        }
    }
}

.form-check {
    label {
        border-bottom: 1px solid #d3d3d3;
        font-family: 'Lato-Regular';
        font-size: 14px;
    }
    text-align: center;
}

.sub-btn {
    display: flex;
    justify-content: center;
    button {
        background: #a1a1a1;
        border-radius: 27px;
        border-color: #a1a1a1;
        color: #fff;
        transition: .5s;
        padding: 9px 70px;
        font-family: 'Lato-Regular';
        font-size: 14px;
    }
    button:hover {
        background: #706f6f;
        border-color: #706f6f;
        color: #fff;
        transition: .5s;
    }
}