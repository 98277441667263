@import '../../assets/styles/_breakpoints.scss';
.advance-Exercise-guide {

    a,
    a:visited,
    a:hover,
    a:active {
        color: inherit;
    }
    width: 100%;
    overflow: hidden;

    .info-wraper {
        background-color: #F5F5F5;
        ;
        margin-top: -6px;
        padding: 10px 180px;

        .info-sec {
            display: flex;
            align-items: center;
            padding: 10px;
            margin: 5px 15px;
            background: #def2cd;
            border-radius: 45px 0 0 0;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            p {
                margin: 0;
            }

            .info-left {
                display: flex;
                align-items: center;
                margin-left: 15px;

                p {
                    margin-left: 15px;
                }
            }

            p:nth-last-child(2) {
                position: relative;
            }

            p:nth-last-child(2)::before {
                content: '|';
                position: absolute;
                left: -7px;
                color: #55b700;
                font-weight: 600;
            }
        }

        .tb-info {
            display: flex;
            justify-content: right;
            align-items: center;
            margin-bottom: 5px;

            span {
                font-family: 'Lato-Bold';
                font-size: 16px;
                margin-right: 10px;
                width: 100px;
                display: block;
                text-transform: uppercase;
                text-align: right;
            }

            img {
                width: 60px;
            }
        }

        .activeimg {
            background-image: url(../../assets/images/personHealth.png);
            background-repeat: no-repeat;
            width: 70px !important;
            height: 70px;
            background-size: 70px 70px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 70px;
            }
        }

        .nactiveimg {
            background-image: url(../../assets/images/personHealth.png);
            background-repeat: no-repeat;
            width: 60px;
            height: 60px;
            background-size: 60px 60px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 60px;
            }
        }

        .app-btn {
            display: flex;
            flex-wrap: wrap;

            button {
                // background-image: linear-gradient(#fbfbfb, #b9b9b7);
                // border: 0;
                // border-radius: 15px;
                // padding: 5px 15px;
                // margin-right: 10px;
                // display: flex;
                // align-items: center;
                // outline: none;
                border: 1px solid #c8c9c6;
                border-radius: 6px;
                padding: 2px 10px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
                background: #def2cd;
                color: #62B01E;
                font-weight: 600;
                margin-top: 8px;

                img {
                    width: 15px;
                    margin-right: 5px;
                }
            }
        }

        @media only screen and (max-width: $breakpoint-xlarge) {
            .info-sec {
                justify-content: center;

                p:nth-last-child(2) {
                    text-align: center;
                }

                p:nth-last-child(2)::before {
                    content: '';
                    position: absolute;
                    left: -7px;
                    color: unset;
                    font-weight: unset;
                }
            }

            .tb-info {
                position: relative;
                top: 25%;
            }
        }
    }

    .advance-Exercise-slider {
        overflow: hidden;
    }

    .slider-img {
        object-fit: cover;
        width: 100%;
        max-height: 835px;
    }

    .slider-conatiner {
        position: relative;

        .cente-text {
            position: absolute;
            top: 33%;
            left: 0;
            right: 0;
            width: 80%;
            margin: 0 auto;
            text-align: center;

            h1 {
                font-family: 'Montserrat-Medium';
                font-size: 56px;
                color: #fff;
                text-transform: uppercase;
            }

            p {
                font-family: 'Montserrat-Medium';
                font-size: 30px;
                color: #fff;
                padding: 12px 0px;
                text-transform: uppercase;
                max-width: 800px;
                margin: auto;
            }

            button {
                background-color: transparent;
                border-radius: 10px;
                border: 3px solid #fff;
                font-family: 'Montserrat-Medium';
                font-size: 32px; /* Fallback for older browsers */
                font-size: 1.5rem; /* Main font size using rem */
                padding: 10px 80px;
                color: #fff;
                text-transform: uppercase;
                cursor: pointer;
                margin-top: 30px;
            }
        }

        .arrow-icon {
            background-image: url(../../assets/images/down-arrow-back.png);
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            background-size: 50px 50px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8%;
            margin-bottom: 10px;
            cursor: pointer;

            img {
                width: 25px;
                height: 14px;
                margin-top: 5px;
            }
        }

        .right-icon {
            position: absolute;
            top: 37%;
            bottom: 0;
            right: 3%;

            .tb-info {
                display: flex;
                justify-content: right;
                align-items: center;
                margin-bottom: 5px;

                span {
                    font-family: 'Lato-Bold';
                    font-size: 16px;
                    margin-right: 10px;
                    width: 100px;
                    display: block;
                    text-transform: uppercase;
                    text-align: right;
                }

                img {
                    width: 70px;
                }

                .health-co {
                    color: #51be58;
                }

                .pro-co {
                    color: #fc00fe;
                }

                .ins-co {
                    color: #fec604;
                }

                .joy-co {
                    color: #0491fe;
                }
            }

            .activeimg {
                background-image: url(../../assets/images/personHealth.png);
                background-repeat: no-repeat;
                width: 72px;
                height: 72px;
                background-size: 70px 72px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 70px;
                }
            }
        }

        &::before {
            background-color: #2827274d;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }

    .discover-section {
        background-image: url(../../assets/images/advance-guide/search-banner.png);
        min-height: 445px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 25px;
        color: #fff;
        text-align: center;

        img {
            margin: 0 auto;
        }

        h1 {
            font-family: 'Lato-Bold';
            font-size: 48px;
            margin: 20px 0 40px;
        }

        p {
            font-family: 'Lato-Regular';
            font-size: 18px;
        }

        h3 {
            font-family: 'Lato-Regular';
            font-size: 28px;
            color: #B6B4FF;
            margin: 30px 0 80px;
        }

        h4 {
            font-family: 'Lato-Regular';
            font-size: 24px;
        }

        @media only screen and (max-width: $breakpoint-large) {
            padding: 10px;
        }
    }

    .cust-review {
        position: relative;
        margin-top: 30px;
        font-family: 'Lato';
        text-align: center;

        .head-title {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 2px;
            margin-bottom: 20px;
            h1 {
                font-size: 32px;
                font-size: 2rem;
                color: #8C8C8C;
                text-transform: uppercase;
                margin: 0;
                position: relative;
                font-family: inherit;
                
            }
            h1:before {
                position: absolute;
                content: "";
                height: 1px;
                background-color: #838080;
                width: 50px;
                top: 50%;
                margin-left: -56px;
            }
            h1:after {
                position: absolute;
                content: "";
                height: 1px;
                background-color: #838080;
                width: 50px;
                margin-left: 6px;
                top: 50%;
            }

            img {
                margin: 2px;
            }
        }

        .write-review {
            position: absolute;
            right: 0;
            top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media (max-width: 700px) {
                display: none;
            }

            p {
                font-family: 'Lato-Light';
                font-size: 12px;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
                padding: 3px 15px;
                text-transform: uppercase;
                border-radius: 5px;
            }

            img {
                width: 25px;
                cursor: pointer;
            }
        }
    }

    .customer_reviews {

        //padding: 0 100px;
        .review-card {
            margin: 10px 10px;
            position: relative;

            .card-review {
                padding: 30px;
                border: 1px solid rgb(197, 195, 195);
                border-radius: 4px;
                margin: 0 20px;
                background-color: white;
                min-height: 492px;

                .head-sec {
                    display: flex;
                    align-items: center;

                    .head-info {
                        margin-left: 15px;

                        p {
                            font-family: 'Lato-Bold';
                            font-size: 14px;
                            margin: 0;
                        }

                        h1 {
                            font-family: 'Lato-Bold';
                            font-size: 20px;
                        }
                    }
                }

                .star-info {
                    display: flex;
                    justify-content: space-between;
                    margin: 15px 10px 10px 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgb(226, 226, 226);

                    i {
                        color: #FFAE31;
                    }

                    .no-colr {
                        color: #B5B5B5;
                    }

                    p {
                        margin: 0;
                        font-family: 'Lato-Regular';
                        font-size: 14px;
                        color: #24231F;
                    }
                }

                .info2 {
                    padding: 10px;
                    position: relative;

                    p {
                        padding: 10px 15px;
                        margin: 0;
                        color: #24231F;
                        font-family: 'Lato-Light';
                        font-size: 18px;
                    }

                    .img2 {
                        position: absolute;
                        right: 0;
                    }

                    span {
                        display: block;
                        text-align: right;
                        padding-top: 30px;
                        font-family: 'Lato-Light';
                        font-size: 12px;
                    }
                }
            }
        }

        .review-card::before {
            content: '  —';
            position: absolute;
            top: -29px;
            left: 29px;
            font-size: 38px;
        }

        @media only screen and (max-width: 1200px) {
            padding: 30px;
        }
    }

    .cust-author2 {
        padding: 30px;

        h1 {
            font-size: 32px;
            font-size: 1.5rem;
            color: #8C8C8C;
            font-family: 'Montserrat-Semibold';
            margin-top: 20px;
            margin-bottom: 50px;
            text-transform: uppercase;
            text-align: center;
        }

        .author-txt {
            background: #00000005;
            padding: 40px 80px;

            h2 {
                font-size: 32px;
                font-size: 1.5rem;
                font-family: 'Lato-Regular';
                color: #434343;
            }

            p:nth-child(2),
            p:nth-child(3) {
                margin-bottom: 3px;
                font-family: 'Lato-Regular';
                font-size: 18px;
            }

            p {
                margin-bottom: 13px;
                font-family: 'Lato-Light';
                font-size: 16px;
            }

            p:last-child {
                margin-bottom: 3px;
                font-family: 'Lato-Italic';
                font-style: italic;
                font-size: 16px;
                margin-top: 22px;
            }
        }
    }

    .cust-author1 {
        padding: 40px 150px;

        @media only screen and (max-width: 800px) {
            padding: 40px;
        }

        h1 {
            font-size: 32px;
            font-size: 1.5rem;
            color: #8C8C8C;
            font-family: 'Montserrat-Semibold';
            margin-top: 10px;
            margin-bottom: 60px;
            text-transform: uppercase;
            text-align: center;
        }

        .author-img {
            img {
                width: 100%;
            }

            .player-icon {
                position: absolute;
                width: 106px;
                top: 42%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }
        }

        .author-txt {
            background: #fff;
            padding: 0 40px;

            .author-img1 {
                position: relative;

                img {
                    width: 100%;
                    padding-left: 6%;
                }
            }

            h3 {
                padding-top: 90px;
                font-size: 18px;
                font-family: 'Montserrat-Bold';
                color: #434343;
            }

            p:nth-child(2) {
                margin-top: 20px;
                font-family: 'Montserrat-Medium-Italic';
                font-size: 12px;
                color: #D73921;
            }

            p:nth-child(3) {
                font-family: 'Lato-Regular';
                font-size: 14px;
                margin-top: 100px;

                @media only screen and (max-width: 1800px) {
                    margin-top: 3%;
                }
            }
        }
    }

    .active {
        border-bottom: 3px solid #659BBF;
    }

    .table {
        &_body {
            box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
            margin-top: 40px;
            overflow: scroll;

            .active {
                border-bottom: 3px solid #659BBF;
            }

            &_head {
                display: flex;
                align-items: center;
                justify-content: left;
                border-bottom: 1px solid #cfcccc;
                width: 80%;
                margin: 0 auto;
                margin-bottom: 50px;
                overflow-x: auto;

                &_item {
                    background-color: #fff;
                    padding: 15px 15px;
                    margin: 0 40px;
                    cursor: pointer;
                    font-size: 24px;
                    font-family: 'Montserrat-Medium';
                }
            }
        }
    }

    .cust-author3 {
        h1 {
            font-size: 32px;
            font-size: 1.5rem;
            color: #8C8C8C;
            font-family: 'Montserrat-Semibold';
            margin-top: 20px;
            margin-bottom: 50px;
            text-transform: uppercase;
            text-align: center;
        }

        .services_item_title {
            position: absolute;
            bottom: 15px;
            font-family: "Lato-Bold";
            font-size: 14px;
        }

        .colr1 {
            color: #56B703
        }

        .colr2 {
            color: #0592FF
        }

        .colr3 {
            color: #FA44B3
        }

        .colr4 {
            color: #FED130
        }
    }
}

.weight-shifter-plan {
    h1 {
        font-family: 'Lato-Bold';
        font-size: 22px;
        color: #8DD54E;
        margin-bottom: 20px;
        margin-left: 70px;
        cursor: pointer;
    }
}

.modal-continer {
    background: #D3D0D0;
    padding: 50px;

    .closem {
        position: absolute;
        right: 15px;
        top: 15px;
        font-weight: bold;
        height: 40px;
        width: 40px;
        border: 2px solid #000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 19px;
    }

    .body-section {
        background: #efefef;

        .baner-section {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: 260px;
            }

            p {
                top: 37%;
                position: absolute;
                color: #fff;
                padding: 24px 0px;
                background: #0404046e;
                width: 100%;
                text-align: center;
                font-family: 'Lato-Regular';
                font-size: 24px;
            }
        }

        .detail-section1 {
            display: flex;
            justify-content: space-between;
            padding: 25px 20px 10px;
            align-items: center;

            button {
                background: #8C8C8C;
                font-family: 'Montserrat-Medium';
                font-size: 16px;
                padding: 8px 16px;
                border: 0;
                border-radius: 4px;
                color: #fff;
                text-transform: uppercase;
            }

            h2 {
                font-family: 'Montserrat-Medium';
                font-size: 32px;
                font-size: 1.5rem;
                color: #595959
            }
        }

        .detail-section2 {
            display: flex;
            justify-content: space-between;
            padding: 0px 20px 10px;
            align-items: center;

            button {
                border: 1px solid #8C8C8C;
                font-family: 'Montserrat-Medium';
                font-size: 16px;
                padding: 8px 16px;
                border-radius: 4px;
                text-transform: uppercase;
                color: #8C8C8C
            }

            h2 {
                font-family: 'Montserrat-Bold';
                font-size: 32px;
                font-size: 1.5rem;
                color: #C63939
            }
        }

        .dis-section {
            padding: 20px;

            p {
                font-size: 24px;
                font-family: 'Lato-Bold';
                color: #595959;
            }

            .dis-section2 {
                li {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin-bottom: 5px;

                    p {
                        margin: 5px 10px 4px 10px;
                        font-size: 16px;
                        font-family: 'Lato-Bold';

                        span {
                            margin-top: 6px;
                            font-size: 16px;
                            font-family: 'Lato-Light';
                        }
                    }
                }
            }

            .dis-section3 {
                border: 2px dashed #606060;
                border-radius: 5px;
                padding: 10px 15px;
                margin-top: 10px;

                p {
                    font-size: 18px;
                    font-family: 'Lato-Regular';
                }
            }
        }
    }
}

.advance-Exercise-guide {
    .botom-icon {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 25px 0;
        flex-wrap: wrap;
        

        @media screen and (max-width: 740px) {
            display: none;
        }

        .setion-name {
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                width: 25px;
                height: 25px;
                margin-right: 15px;
            }

            p {
                // // font-family: 'Montserrat-Regular';
                font-size: 24px;
                margin: 0;
            }
        }

        span {
            font-size: 25px;
            margin-right: 30px;
            margin-left: 30px;
            color: #cdcdcd;
        }
    }

    .section2 {
        max-width: 1920px;
        margin: auto;
        margin-bottom: 50px;

        &_subTitle {
            //   border-left: 5px solid #57b7039f;
            padding-left: 20px;
            position: relative;
            margin: 10px 0 15px 160px;
            font-size: 28px;
            text-transform: uppercase;
            font-weight: 300;
            color: #595959;
            display: flex;
            align-items: center;
            max-width: 1550px;

            @media only screen and (max-width: $breakpoint-large) {
                margin: 10px 65px;
                align-items: center;
                flex-direction: column;
                padding-left: 0px;
            }
            &_goldText {
                @media (min-width: $breakpoint-medium) {
                    // Styles for medium screens and above
                    position: absolute;
                    
                }
                display: inline;
                text-align: center;
                font-size: 0.8rem;
                font-weight: 300;
                color: #D4AF37;
                border: 1px solid #D4AF37;
                margin: 10px 0 15px 160px;
                border-radius: 10px;
                padding: 10px;
                right: 5%;
                cursor: pointer;
                @media only screen and (max-width: 768px) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }

        .course-container {
            width: 80%;
            display: flex;
            justify-content: space-between;
            margin: auto;
            height: 567px; 
            @media only screen and (max-width: $breakpoint-xlarge) {
                flex-direction: column;
                height: auto;
            }
            @media only screen and (max-width: $breakpoint-medium) {
                width: 100%;
            }
          }

          .course-background {
            position: relative;
            width: 70%; 
            height: 80%; 
            padding-bottom: 10%; 
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: flex-end; 
            justify-content: flex-start;
            margin-top:40px;
            @media only screen and (max-width: $breakpoint-xlarge) {
                width: 100%;
                height: 300px;
            }
            @media only screen and (max-width: $breakpoint-medium) {
                height: 200px;
                margin-bottom: 5px;
            }
          }

          .text-with-bar {
            display: flex;
            align-items: center; 
            margin-left: 40px; 
            flex-direction: row;
            justify-content: center; 
            height: 90px; 
            margin-bottom: -63px;
            @media only screen and (max-width: 1430px) {
                height: 60px;
            } 
            @media only screen and (max-width: $breakpoint-xlarge) {
                margin-bottom: -40px;
                height: 50px;
            }
            @media only screen and (max-width: $breakpoint-medium) {
                height:40px;
            }
            @media only screen and (max-width: $breakpoint-small) {
                height: 30px;
                margin-bottom: -20px;
            }
          }

          .vertical-bar {
            width: 8px;
            height: 100%; 
            background-color: white; 
            margin-right: 15px; 
            opacity: 0.6;
            @media only screen and (max-width: 1430px) {
                width: 3px;
            }
          }

          .course-text {
            font-size: 48px;
            font-weight: bold;
            color: white;
            line-height: 1.2; 
            text-align: left;
            margin: 0;
            opacity: 0.6;
            @media only screen and (max-width: 1430px) {
                font-size: 32px;
            }
            @media only screen and (max-width: $breakpoint-xlarge) {
                font-size: 28px;
            }
            @media only screen and (max-width: $breakpoint-medium) {
                font-size: 24px;
            }
            @media only screen and (max-width: $breakpoint-small) {
                font-size: 18px;
            }
          }

          .course-details {
            width: 30%; 
            border: 2px solid #ddd; 
            border-radius: 8px; 
            padding: 40px;
            display: flex;
            justify-content: space-between; 
            height: 100%; 
            flex-wrap: wrap;
            @media only screen and (max-width: 1510px) {
                padding: 20px;
            }
            @media only screen and (max-width: $breakpoint-xlarge) {
                width: 100%;
                margin: 0;
                padding: 20px;
            }

            @media only screen and (max-width: $breakpoint-medium) {
                max-width: 400px;
                margin: 0 auto;
            }
          }

          .pre-course {
            color: "#333333";
            opacity: 0.6;
          }

          .presenter-rating-section {
            margin-top: 10px; 

            @media only screen and (max-width: 1510px) {
                margin: 0; 
            }    
          }
          
          .price {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          
          .old-price {
            text-decoration: line-through;
            color: #999;
          }
          
          .new-price {
            font-size: 24px;
            font-weight: bold;
            color:#000; 
          }
          
          .course-actions {
            display: flex;
            gap: 10px;
            margin-top: 40px;

            @media only screen and (max-width: $breakpoint-xlarge) {
                margin-top:20px;
            }
          }

          .btn {
            padding: 10px 20px;
            font-size: 16px;
            border: 2px solid green; 
            border-radius: 25px;     
            background-color: transparent;            
            cursor: pointer;        
            text-align: center; 
            width: auto;  
            height:48px;
            @media only screen and (max-width: $breakpoint-medium) {
                padding: 8px 16px; 
                font-size: 14px;
            }           
          }
          
        //   .btn:hover {
        //     background-color: green;  
        //     color: white;            
        //   }
          
          .add-to-cart {
            background-color: #4caf50;
            color: white;
            text-transform: capitalize; 
          }
          
          .join-now {
            color: black;
            text-transform: capitalize; 
          }

        .courses {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: nowrap;
        @media only screen and (max-width: $breakpoint-medium) {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }

        //gap: 20px;
        max-width: 1920px;
        margin: auto;
        padding: 0 160px 0 120px;
        &_banner {
            height: 250px;
            width: 800px;
            p {
                padding: 25px 15px;
                text-align: center;
                font-size: 22px;
            }
        }
        &_desc {
            width: 750px;
            padding: 0 30px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 18px;
            color: #434343;
            &_btn {
                border: 1px solid #595959;
                border-radius: 10px;
                padding: 5px 10px;
                display: inline;
                margin: auto;
            }
        }
        @media only screen and (max-width: 1200px) {
            &_banner {
                width: 100%;
                margin-bottom: 10px;
            }
            &_desc {
                width: 100%;
                margin-bottom: 10px;
                padding: 0 10px;
                text-align: center;
            }
            
        }
        @media only screen and (max-width: 600px) {
            padding: 0 30px;
        }
    }

    
        .card {
            max-width: 1100px;
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
            position: relative;
            margin: 50px auto;

            &_details {
                width: 250px;
                padding: 10px 10px;
                box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.5);
                position: relative;
                
                &_thumbnail {
                    position: absolute;
                    width: 75px;
                    height: 75px;
                    top:10px;
                    right: 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    overflow: hidden;
                    @media only screen and (max-width: $breakpoint-medium) {
                        width: 60px;
                        height: 60px;
                    }
                }

                .readMore {
                    color: #B0B0B0;
                    padding: 5px 10px;
                    border: 1px solid #B0B0B0;
                    border-radius: 5px;
                    display: inline;
                    position: relative;
                    top: -10px;
                    left: 125px;
                }

                &_desc {
                    margin: 10px 0;
                    font-size: 18px;
                    color: #434343d2;

                    
                }
                .collapsed {
                    height: 163px; /* Set the initial height you want when collapsed */
                    overflow: hidden;
                    transition: height 1s ease; /* Add smooth transition for animation */
                  }
                  
                  .expanded {
                    height: auto; /* Expand to content height when expanded */
                    transition: height 1s ease; 
                    
                  }

                &_price {
                    font-weight: bolder;
                    font-family: 'Montserrat';
                    font-size: 15px;
                    color: #595959;
                    flex: 0 0 20%;

                    .discount {
                        text-decoration: line-through;
                        font-size: 14px;
                    }
                }
            }

            @media only screen and (max-width: 1000px) {
                margin: 30px;

                &_banner {
                    width: 100%;
                }
                &_details {
                    width: 100%;

                    .readMore {
                        left: 200px;
                        @media only screen and (max-width: $breakpoint-medium) {
                            left: 160px;

                        }
                    }
                }
            }

            
                &_btn {
                    display: inline;
                    border-radius: 4px;
                    padding: 10px;
                    text-align: center; 
                }
                
        
                &_btns {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    margin: 20px 0;
                    
        
                    &_btn {
                        border-radius: 4px;
                        padding: 10px;
                        text-align: center;  
                        flex:100%;
                    }
        
                    .light {
                        color: #8C8C8C;
                        border: 1px solid #8C8C8C;               
                    }
        
                    .dark {
                        color: white;
                        background-color: #8C8C8C;                    
                    }
                }
        
                &_list {
                    margin: 30px 0;
                    flex:1;
                    &_item {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        margin: 25px 0;
                        text-transform: none;
                        
        
                        .duration {
                            flex: 0 0 45%;
                            p {
                                margin: 0;
                            }
                        }
        
                        .light {
                            color: #8C8C8C;
                            border: 1px solid #8C8C8C;               
                        }
                        .dark {
                            color: white;
                            background-color: #8C8C8C;                    
                        }
        
                        .cart-button {
                            @media (max-width: $breakpoint-medium) {
                                  flex: 100%; /* Make the last item take up the full width */
                                }
                        }
                    }
                }
            
        }
    
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        overflow: hidden;
        max-width: 1920px;
        padding: 0 160px 0 120px;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        &_item {
            height: 250px;
            width: 260px;

            p {
                padding: 25px 15px;
                text-align: center;
                font-size: 18px;
                font-family: 'Lato-Regular';

                a {
                    font-size: 14px;
                    font-family: 'Lato-Regular';
                    text-decoration: underline;
                }
            }
            @media only screen and (max-width: 768px) {
                
                min-width: 330px;
                min-height: 250px;

            }
        }

        @media only screen and (max-width: 768px) {
            padding: 0 30px;
        }
    }
}

@media (max-width:$breakpoint-large) {
    .advance-Exercise-guide.page-w .slider-conatiner .cente-text {
        top: 50%;
        transform: translateY(-50%);

    }
}

@media (min-width: $breakpoint-medium) and (max-width:992px) {}

@media (max-width:768px) {
    .advance-Exercise-guide.page-w .right-icon {
        display: none;
    }

    .advance-Exercise-guide .tb-info .dotbtns {
        display: none;
    }

    .advance-Exercise-guide .slick-dots li {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 4px;
        padding: 0;
        cursor: pointer;
    }

    .advance-Exercise-guide .tb-info img {
        width: 40px;
    }

    .advance-Exercise-guide .slick-active .tb-info img {
        width: 40px;
    }

    .advance-Exercise-guide .slick-dots .slick-active {
        background-size: 41px 41px;
    }

    .advance-Exercise-guide .slick-dots {
        display: flex !important;
        width: 100%;
        right: auto;
        padding: 10px;
        justify-content: center;
        margin: 0;
        bottom: 10px;
        top: 88%;
        transition: bottom 300ms;
        height: 55px;

        @media only screen and (max-width: 768px) {
            display: none !important;
        }
    }

    .advance-Exercise-slider .slick-active .tb-info span {
        text-align: center;
        position: absolute;
        top: -32px;
        left: -27px;
    }
}

@media (max-width:767px) {
    .advance-Exercise-guide.page-w .exercise-secion-right .section-right {
        width: 100%;
        margin-left: 0%;
    }

    .advance-Exercise-guide.page-w .exercise-secion-left .section-right {
        width: 100%;
    }
}

@media (max-width:$breakpoint-large) {
    .advance-Exercise-guide.page-w .slider-conatiner .cente-text h1 {
        font-size: 30px;
    }

    .advance-Exercise-guide.page-w .slider-conatiner .cente-text button {
        padding: 5px 20px;
    }

    .advance-Exercise-guide.page-w .slider-conatiner .cente-text p {
        font-size: 22px;
    }

    .advance-Exercise-guide.page-w .exercise-secion-right .section-right {
        width: 100%;
        margin-left: 0%;
    }

    .advance-Exercise-guide.page-w .exercise-secion-left .section-right {
        width: 100%;
    }

    .advance-Exercise-guide.page-w .exercise-secion-right h2,
    .advance-Exercise-guide.page-w .exercise-secion-left h2,
    .advance-Exercise-guide.page-w .exercise-secion-right h1,
    .advance-Exercise-guide.page-w .exercise-secion-left h1 {
        font-size: 30px;
    }

    .exercise-secion-right .person-health-img,
    .exercise-secion-left .person-health-img {
        width: 100px;
        margin: 0 auto;
    }

    .advance-Exercise-guide .info-wraper {
        padding: 10px 44px;
    }

    .advance-Exercise-guide .list {
        padding: 0 10px 0 10px !important;
    }

    .advance-Exercise-guide .cust-author2 .author-txt {
        background: #00000005;
        padding: 40px 10px;
        text-align: center;
    }

    .advance-Exercise-guide .cust-author1 .author-txt .author-img1 img {
        width: 100%;
        padding-left: 0%;
        margin-top: 10px;
        border-radius: 10px;
    }

    .advance-Exercise-guide .cust-author1 .author-txt {
        background: #fff;
        padding: 0 0px;
        border-radius: 10px;
    }

    .modal-continer .body-section .detail-section1 {
        flex-wrap: wrap;
        justify-content: center;
    }

    .modal-continer .body-section .detail-section2 {
        justify-content: center;
        flex-wrap: wrap;
    }

    .modal-continer {
        padding: 30px;
    }

    .modal-continer .closem {
        right: 5px;
        top: 5px;
    }
}

@media (max-width:1400px) {
    .advance-Exercise-guide.page-w .exercise-secion-right .section-right {
        width: 100%;
        margin-left: 0%;
    }

    .advance-Exercise-guide.page-w .exercise-secion-left .section-right {
        width: 100%;
    }
}

@media only screen and (max-width: $breakpoint-large) {
    .advance-Exercise-guide .list {
        padding: 0 60px 0 60px;
    }

    .service_card {
        position: relative;
        width: 100%;
    }

    .advance-Exercise-guide .list_item {
        height: 250px;
        
    }

    .advance-Exercise-guide .discover-section {
        min-height: 683px;
    }

    .advance-Exercise-guide .info-wraper .tb-info {
        position: relative;
        top: 0%;
    }

    .advance-Exercise-guide .info-wraper .tb-info {
        justify-content: center;
    }

    .advance-Exercise-guide .info-wraper .nactiveimg {
        margin: 0 15px;
    }
}
.customers {
    &_content {
        margin: auto;
        max-width: 1800px;
        text-align: center;
        padding: 50px 0;

        &_head {
            
            h1 {
            font-size: 2rem;
            font-family: 'Lato';
            color: #8C8C8C;
            text-transform: uppercase;
            position: relative;
            }

            @media only screen and (min-width: $breakpoint-large) {
                h1:before {
                    position: absolute;
                    content: "";
                    height: 1px;
                    background-color: #838080;
                    width: 50px;
                    top: 50%;
                    margin-left: -56px;
                }
                h1:after {
                    position: absolute;
                    content: "";
                    height: 1px;
                    background-color: #838080;
                    width: 50px;
                    margin-left: 6px;
                    top: 50%;
                }
            }
        }

        &_star {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px;

            &_title {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 2px;
                color: #8C8C8C;
                margin-top: 15px;
            }
        }
    }
}
.modal-dialog {
    max-width: 570px;
}

