.margins {
    margin: 0px 8%;
}

.exercise-air-wrapper {
    overflow-x: hidden;
    .Fusion-head {
        position: relative;
        padding: 170px;
        padding-bottom: 280px;
        padding-top: 200px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 340px;
        background-image: url('../../assets/images/third-set/fusion-bg.png');
        color: white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &_details {
            .breadCrumbs {
                font-family: 'Montserrat-Medium';
                font-size: 32px;
                opacity: .49;
                margin-bottom: 20px;
            }
            .title {
                font-family: 'Montserrat-Bold';
                font-size: 48px;
                text-transform: uppercase;
                margin-bottom: 45px;
            }
            .subtitle {
                font-family: 'Lato-Bold';
                font-size: 40px;
                margin-bottom: 25px;
            }
            .data {
                font-family: 'Lato-Bold';
                font-size: 20px;
                max-width: 640px;
            }
        }
        #right-img {
            margin-top: 70px;
            img {
                height: 420px;
                min-width: 420px;
            }
        }
        @media only screen and (max-width: 600px) {
            padding: 100px 30px !important;
            gap: unset;
            &_details {
                .data {
                    max-width: unset;
                }
            }
        }
        @media only screen and (max-width: 900px) {
            padding: 120px 60px;
            gap: unset;
        }
        @media only screen and (max-width: 1200px) {
            #right-img {
                display: none;
            }
        }
    }
    .banner-secs {
        position: relative;
        img {
            width: 100%;
        }
        &::before {
            background-color: #2827278e;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
        .content-sec {
            position: absolute;
            width: 45%;
            color: #fff;
            top: 120px;
            padding-left: 115px;
            p:nth-child(1) {
                font-size: 16px;
                font-family: 'Montserrat-Medium';
                opacity: .5;
            }
            h1 {
                font-family: 'Montserrat-Bold';
                font-size: 35px;
                margin-bottom: 40px;
            }
            h2 {
                font-family: 'Lato-Bold';
                font-size: 30px;
                margin-bottom: 20px;
            }
            p {
                font-family: 'Lato-Bold';
                font-size: 15px;
                padding: 5px 20px 5px 0;
            }
        }
        .right-img {
            position: absolute;
            right: 0px;
            right: 250px;
            top: 220px;
            img {
                width: 250px;
                height: 250px;
            }
        }
    }
    .info-wraper {
        background-color: #eff8e5;
        margin-top: -6px;
        padding: 12px 150px;
        .info-sec {
            display: flex;
            align-items: center;
            padding: 10px;
            margin: 5px 15px;
            background: #def2cd;
            border-radius: 45px 0 0 0;
            justify-content: space-between;
            flex-wrap: wrap;
            p {
                margin: 0;
            }
            .info-left {
                display: flex;
                align-items: center;
                margin-left: 15px;
                p {
                    margin-left: 15px;
                }
            }
            p:nth-last-child(2) {
                position: relative;
            }
            p:nth-last-child(2)::before {
                content: '|';
                position: absolute;
                left: -7px;
                color: #55b700;
                font-weight: 600;
            }
        }
        .tb-info {
            display: flex;
            justify-content: right;
            align-items: center;
            margin-bottom: 5px;
            span {
                font-family: 'Lato-Bold';
                font-size: 16px;
                margin-right: 10px;
                width: 100px;
                display: block;
                text-transform: uppercase;
                text-align: right;
            }
            img {
                width: 50px;
            }
        }
        .activeimg {
            background-image: url(../../assets/images/personHealth.png);
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            background-size: 50px 50px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 50px;
            }
        }
        .app-btn {
            display: flex;
            flex-wrap: wrap;
            button {
                // background-image: linear-gradient(#fbfbfb, #b9b9b7);
                // border: 0;
                // border-radius: 10px;
                // padding: 2px 10px;
                // margin-right: 10px;
                // display: flex;
                // align-items: center;
                // outline: none;
                // img {
                //     width: 15px;
                //     margin-right: 5px;
                // }
                border: 1px solid #c8c9c6;
                border-radius: 6px;
                padding: 2px 10px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                outline: none;
                background: #def2cd;
                color: #62B01E;
                font-weight: 600;
                margin-top: 8px;
            }
        }
    }
    .fusion-exe {
        h1 {
            font-size: 48px;
            padding: 40px 0;
            padding-left: 160px;
            text-transform: uppercase;
            margin: 0;
            font-family: 'Montserrat-Medium';
            color: #595959;
        }
    }
    .fusion-gal {
        width: 100%;
        padding: 20px 80px 40px;
        max-width: 1852px;
        margin: 0 auto;
        .fusion-exe-gl {
            position: relative;
            h4 {
                position: absolute;
                bottom: -8px;
                padding: 40px 0;
                background-color: #5555559c;
                width: 100%;
                text-align: center;
                color: #fff;
                font-family: 'Montserrat-Bold';
                font-size: 32px;
            }
        }
        .fu-active-gal {
            position: absolute;
            width: 100%;
            margin-top: 30px;
            .triangle-wrapper-100 {
                width: 100%;
                height: 25px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
            .triangle-right {
                right: 0px;
                background: linear-gradient(to right bottom, #DFDFDF 50%, transparent 50%);
                width: 50%;
                height: 25px;
            }
            .triangle-left {
                left: 0px;
                background: linear-gradient(to right bottom, #DFDFDF 50%, transparent 50%);
                width: 50%;
                height: 25px;
                transform: scaleX(-1);
            }
        }
    }
    .fusion-con-gl {
        margin: 30px 0px 20px 78px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .gal-con {
            width: 45%;
            z-index: 4;
            margin-left: 100px;
            h1 {
                font-size: 48px;
                font-family: 'Montserrat-Medium';
                color: #595959;
                padding: 0 20px;
                padding-left: 50px;
                padding-bottom: 10px;
            }
            .gal-con-text {
                background-color: #F2F2F2;
                padding: 40px;
                border-radius: 4px;
                opacity: .8;
                p {
                    font-family: 'Lato-Regular';
                    font-size: 17px;
                    line-height: 17px;
                    width: 1600px;
                }
                li {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 17px;
                    font-family: 'Lato-regular';
                    line-height: 17px;
                    margin-bottom: 25px;
                    span {
                        font-family: 'Lato-Bold';
                        font-size: 14px;
                        line-height: 15px;
                    }
                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .gal-con-img {
            width: 49%;
            z-index: 4;
            position: relative;
            .fu-dis {
                width: 70%;
                margin: 0 auto;
                margin-top: 14px;
                position: absolute;
                top: 66%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                .fu-head {
                    background: #fff;
                    margin: 0;
                    text-align: center;
                    h2 {
                        background: #fff;
                        border-bottom: 1px solid #46a1fb;
                        display: inline-block;
                        padding: 20px 0;
                        text-align: center;
                        margin: 0;
                        font-family: 'Montserrat-Medium';
                        font-size: 17px;
                    }
                }
                .fu-dis-con {
                    opacity: .8;
                    background: #fff;
                    .fu-dis-txt {
                        padding: 40px;
                        h4 {
                            font-family: 'Lato-Regular';
                            font-size: 20px;
                            text-transform: uppercase;
                        }
                        p {
                            font-family: 'Lato-Regular';
                            font-size: 17px;
                        }
                    }
                    .fu-dis-time {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 40px;
                        font-family: 'Lato-light';
                        font-size: 16px;
                        span {
                            font-family: 'Lato-regular';
                            font-size: 20px;
                        }
                    }
                    .fu-dis-btn {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 40px;
                        padding-bottom: 40px;
                        margin-bottom: 20px;
                        button {
                            border-radius: 43px;
                            padding: 2px 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 20px;
                            width: 100%;
                            background: transparent;
                            border: 2px solid #898f95;
                            outline: none;
                            background-color: #fff;
                            font-family: 'Lato-Regular';
                            font-size: 16px;
                            span {
                                font-family: 'Lato-Regular';
                                font-size: 16px;
                            }
                        }
                        button:nth-child(2) {
                            margin: 0 10px;
                            background-color: #fff;
                        }
                        button:nth-child(3) {
                            background-color: #8C8C8C;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .back-img {
            position: absolute;
            right: 15px;
            top: 0px;
            width: 60%;
            img {
                width: 100%;
                height: 580px;
            }
        }
    }
    .slider-secs {
        padding: 20px;
        h1 {
            font-family: 'Montserrat-Medium';
            font-size: 48px;
            text-align: center;
            padding-bottom: 70px;
            margin-top: 15px;
            text-transform: uppercase;
            color: #595959;
        }
        .slider-item {
            padding: 10px 30px;
            text-align: center;
            h2 {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #595959;
                margin: 20px 0;
            }
            img {
                border-radius: 50%;
                width: 300px;
                margin: 0 auto;
            }
            p {
                font-family: 'Lato-light';
                font-size: 16px;
            }
        }
        .slick-prev:before {
            font-family: "Font Awesome 5 Free";
            content: "\f053";
            color: #000;
            font-size: 30px;
            font-weight: bold;
        }
        .slick-next:before {
            font-family: "Font Awesome 5 Free";
            content: "\f054";
            color: #000;
            font-size: 30px;
            font-weight: bold;
        }
    }
    .left-clender {
        font-family: 'Montserrat-Bold';
        font-size: 22px;
        color: #9E9E9E;
        margin-top: 140px;
        ul {
            border-right: 3px solid #efefef;
            position: relative;
        }
        li {
            padding: 10px 0;
            cursor: pointer;
        }
        .cal-active {
            border-right: 3px solid #678eeb;
            margin-right: -3px;
        }
    }
    .gal-clender {
        padding: 20px 0;
        h1 {
            font-family: 'Montserrat-Bold';
            font-size: 26px;
            color: #9E9E9E;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    .revie-card {
        width: 100%;
        display: inline-block;
        border: 1px solid red;
        padding: 15px;
    }
}

.tab-content-show {
    display: block;
}

.tab-content-hide {
    display: none;
}

@media (max-width:1250px) {
    .exercise-air-wrapper .fusion-con-gl .back-img {
        img {
            width: 100%;
            height: 480px;
        }
    }
}

@media (min-width:1852px) {
    .exercise-air-wrapper .center-vi {
        margin: 0 auto;
        position: relative;
        right: 74px;
    }
}

@media (max-width:1125px) {
    .exercise-air-wrapper .fusion-con-gl .back-img {
        img {
            width: 100%;
            height: 555px;
        }
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img .fu-dis {
        width: 76%;
        margin: 0 auto;
        margin-top: 50px;
        margin-right: 70px;
        position: static;
        left: 0;
        right: 0;
        transform: none;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img .fu-dis .fu-dis-con .fu-dis-btn {
        padding: 0 0px 20px;
    }
    .exercise-air-wrapper .banner-secs .content-sec {
        position: absolute;
        width: 84%;
        color: #fff;
        top: 34px;
        padding-left: 50px;
    }
}

@media (max-width:820px) {
    .exercise-air-wrapper .banner-secs .content-sec {
        width: 100%;
        padding: 15px;
    }
    .advance-Exercise.page-w .info-wraper {
        padding: 12px 20px;
    }
    .exercise-air-wrapper .info-wraper {
        padding: 12px 15px;
    }
    .exercise-air-wrapper .info-wraper .app-btn {
        justify-content: center;
        button {
            margin-bottom: 10px;
        }
    }
    .exercise-air-wrapper .fusion-exe h1 {
        padding: 20px 0 15px 20px;
    }
    .exercise-air-wrapper .fusion-con-gl {
        margin: 30px 0px 20px 0px;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con {
        width: 100%;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img {
        width: 100%;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img .fu-dis {
        width: 80%;
        background: #cdc7c7;
        margin: 80px auto 0;
        position: static;
        left: 0;
        right: 0;
        transform: none;
    }
    .exercise-air-wrapper .slider-secs .slider-item img {
        width: 135px;
        height: 135px;
    }
    .exercise-air-wrapper .banner-secs .right-img {
        img {
            min-height: 271px;
        }
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img .fu-dis .fu-dis-con {
        .fu-dis-txt {
            padding: 15px;
        }
        .fu-dis-time {
            padding: 15px;
        }
        .fu-dis-btn {
            padding: 15px;
            flex-wrap: wrap;
        }
    }
    .exercise-air-wrapper .fusion-con-gl .back-img {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 100%;
    }
    .exercise-air-wrapper .fusion-con-gl .back-img img {
        width: 100%;
        height: 444px;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con h1 {
        text-align: center;
        padding-top: 10px;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con .gal-con-text {
        background-color: #F2F2F2;
        padding: 20px;
        border-radius: 4px;
        opacity: .8;
        margin: 0 auto;
        width: 80%;
    }
    .exercise-air-wrapper .fusion-gal .fu-active-gal {
        //  border-left: 50px solid transparent;
        //  border-right: 50px solid transparent;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img .fu-dis .fu-dis-con .fu-dis-btn button:nth-child(2) {
        margin: 0px;
        background-color: #fff;
    }
    .exercise-air-wrapper .fusion-con-gl .gal-con-img .fu-dis .fu-dis-con .fu-dis-btn button {
        margin: 10px 0;
    }
    .exercise-air-wrapper .banner-secs .content-sec p {
        font-family: 'Lato-Bold';
        font-size: 14px;
        padding: 5px 2px 5px 0;
    }
    .exercise-air-wrapper .banner-secs .content-sec {
        top: 10px;
    }
    .exercise-air-wrapper .banner-secs .content-sec h1 {
        margin-bottom: 10px;
    }
    .exercise-air-wrapper .banner-secs img {
        width: 100%;
        min-height: 680px;
    }
    .exercise-air-wrapper .fusion-gal {
        width: 100%;
        margin: 0 auto;
        padding: 20px 35px 40px;
        .col-sm-3 {
            padding: 0 5px;
        }
    }
    .exercise-air-wrapper .fusion-gal .fusion-exe-gl h4 {
        font-size: 11PX;
    }
    .m-5 {
        margin: 15px !important;
    }
}