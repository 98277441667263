
.discover-metawheel {
    a {
        color: white;
    }

    &_banner {
        background-image: url('../../assets/images/metawheel/banner1.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        margin-left: -34px;
        min-height: 1036px;
        &_content {
            padding: 172px 0px 50px 120px;
            font-size: 50px;
            font-family: 'Montserrat-medium';
            letter-spacing: 2px;
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 942px;
            width: 85%;
            margin: 0 auto;
            flex-wrap: wrap;
            &_cont {
                width: 50%;
                h2 {
                    font-size: 48px;
                    font-family: "Heebo-Bold";
                    color: #000;
                }
                p:nth-child(2) {
                    font-family: 'Lato-Regular';
                    font-size: 18px;
                    padding: 25px 0;
                }
                p:nth-child(3) {
                    font-family: 'Lato-Italic';
                    font-size: 24px;
                }
                button {
                    border: 0;
                    border-radius: 38px;
                    font-family: Montserrat-Bold;
                    font-size: 16px;
                    padding: 10px 50px;
                }
                ul {
                    li {
                        font-family: 'Heebo-Medium';
                        font-size: 24px;
                        padding: 5px 0;
                    }
                }
            }
            &_rightImg {
                width: 50%;
                p {
                    font-family: 'Lato-Regular';
                    font-size: 24px;
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }
    }
    .content-sec1 {
        background-color: #fff;
        padding: 100px 100px 0 100px;
        .container-img {}
        .container-text {
            color: #707070;
            h2 {
                font-family: 'Heebo-Bold';
                font-size: 48px;
                display: flex;
                justify-content: left;
                align-items: center;
            }
            p:nth-child(2) {
                position: relative;
                left: 56px;
                bottom: 20px;
                font-family: 'Lato-Regular';
                font-size: 16px;
            }
            p:nth-child(3) {
                font-family: 'Lato-Bold';
                font-size: 20px;
            }
            p {
                font-family: 'Lato-Regular';
                font-size: 16px;
            }
            h1 {
                font-family: 'georgiai';
                font-size: 42px;
                color: #DFDFDF
            }
        }
    }
    .content-sec2 {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 40px 100px 0 100px;
        flex-wrap: wrap;
        .container-text {
            width: 50%;
            color: #707070;
            h2 {
                font-family: 'Heebo-Bold';
                font-size: 48px;
                display: flex;
                justify-content: left;
                align-items: center;
                padding-bottom: 50px;
            }
            ul {
                li {
                    font-family: 'Lato-Regular';
                    font-size: 16px;
                    padding: 20px 0;
                    display: flex;
                    align-items: baseline;
                    i {
                        color: #8DBF4C;
                        margin-right: 10px;
                        font-size: 14px;
                    }
                    span {
                        display: block;
                    }
                }
            }
            .btn-sec1 {
                display: flex;
                justify-content: left;
                align-items: center;
                padding-top: 20px;
                button:nth-child(1) {
                    border: 0;
                    background: #8DBF4C;
                    border-radius: 30px;
                    padding: 12px 0px;
                    font-size: 16px;
                    font-family: 'Montserrat-Bold';
                    color: #fff;
                    margin-right: 20px;
                    width: 222px;
                }
                button:nth-child(2) {
                    border: 1px solid #8DBF4C;
                    background: #fff;
                    border-radius: 30px;
                    padding: 12px 0px;
                    font-size: 16px;
                    font-family: 'Montserrat-Bold';
                    color: #8DBF4C;
                    display: flex;
                    width: 222px;
                    justify-content: center;
                    align-items: center;
                    img {
                        margin-right: 10px
                    }
                }
            }
        }
        .container-img {
            width: 50%;
        }
    }
    .grid-container {
        background-color: #fff;
        padding: 40px 100px 0 100px;
        .grid-section1 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .grid-text-sec {
                width: 50%;
                padding: 5px;
                position: relative;
                .grid-text {
                    background: #F2F2F2;
                    height: 500px;
                    padding: 60px;
                    h2 {
                        font-size: 36px;
                        font-family: 'Heebo-Bold';
                        color: #595959;
                        padding: 0 50px 26px 0;
                        line-height: 50px;
                    }
                    .blu1 {
                        color: #0592ff94
                    }
                    .per1 {
                        color: #fa44b3b0
                    }
                    .org1 {
                        color: #fed130a3
                    }
                    p {
                        font-size: 20px;
                        font-family: 'Lato-Regular';
                        color: #707070;
                        line-height: 30px;
                    }
                    h1 {
                        font-size: 120px;
                        font-family: 'Montserrat-Bold';
                        text-transform: uppercase;
                        color: #56b703a6;
                        position: absolute;
                        bottom: 0px;
                        right: 50px;
                        opacity: .3;
                    }
                }
            }
            img {
                width: 50%;
                padding: 5px;
                height: 500px;
            }
        }
        .grid-section1:nth-child(1) .grid-text-sec .grid-text {
            border-radius: 0px 200px 0 0;
        }
        .grid-section1:nth-child(4) .grid-text-sec .grid-text {
            border-radius: 0px 0px 0px 200px;
        }
    }
    .grid-slider {
        padding: 30 0px;
        position: relative;
        min-height: 600px;
        h2 {
            font-size: 48px;
            text-align: center;
            padding: 70px 0 50px 0;
            color: #B5B5B5;
            font-family: 'Montserrat-Bold';
        }
        marquee {
            font-size: 110px;
            font-family: 'Heebo-Bold';
            color: #8c8c8c3d;
            margin: 70px 0 0 0;
            text-transform: uppercase;
            // position: absolute;
            top: 80px;
        }
    }
    .grid-img-sec {
        position: relative;
        // top: 240px;
        background: #efefef;
        background-image: url('../../assets/images/metawheel/banner-img.png');
        background-size: 100% 100%;
        height: 1027px;
        .text-conatiner {
            position: absolute;
            top: 0px;
            width: 50%;
            left: 75px;
            background: #efefefd1;
            height: 100%;
            padding: 80px 80px 45px 100px;
            h2 {
                font-family: 'Heebo-Medium';
                font-size: 48px;
                color: #595959;
                text-transform: uppercase;
                padding-bottom: 20px;
            }
            p {
                font-family: 'Lato-Regular';
                font-size: 16px;
                color: #595959
            }
            span {
                font-family: 'Lato-Italic';
                font-size: 16px;
                color: #000;
                width: 70%;
                margin: 0 auto;
                display: block;
                padding: 20px 0;
            }
            h3 {
                font-family: 'Lato-Bold';
                font-size: 14px;
                color: #595959;
                text-align: right;
                padding-right: 50px;
            }
            h5 {
                font-family: 'Lato-Regular';
                font-size: 12px;
                color: #595959;
                text-align: right;
            }
        }
    }
    .grid-plan-sec {
        padding: 80px 140px;
        // margin-top: 180px;
        .top-space {
            margin-top: 66px;
        }
        h1 {
            background: #8DBF4C;
            text-align: center;
            color: #fff;
            padding: 18px 0px;
            font-size: 24px;
            font-family: 'LATO-BOLD';
        }
        h2 {
            font-size: 48px;
            font-family: 'Montserrat-Bold';
            color: #595959;
            padding-bottom: 50px;
            text-align: center;
        }
        .plan-sec {
            border: 2px solid #871DBF;
            border-radius: 8px;
            text-align: left;
            min-height: 1121px;
            .plan-sec1 {
                padding: 30px 30px 0 30px;
                border-bottom: 2px dashed #8DBF4C;
                h3 {
                    font-size: xx-large;
                    font-family: 'Montserrat-Bold';
                    padding: 20px 0;
                    color: #871DBF;
                }
                p {
                    font-family: 'Lato-Light';
                    font-size: 16px;
                    color: #595959;
                }
                .price-sec {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    padding: 20px 0;
                    flex-wrap: wrap;
                    h2 {
                        font-size: xx-large;
                        font-family: 'georgiab';
                        color: #030303;
                        padding-bottom: 0px;
                        text-align: left;
                        margin: 0;
                        sub {
                            font-size: 20px;
                            bottom: 0px;
                        }
                    }
                    label {
                        font-size: 12px;
                        font-family: 'Lato-Regular';
                        color: #F31717;
                    }
                    button {
                        padding: 8px 30px;
                        border: 2px solid #8DBF4C;
                        border-radius: 50px;
                        font-size: 16px;
                        font-family: 'Montserrat-Bold';
                        color: #8DBF4C;
                        background: #fff;
                        position: relative;
                        bottom: 8px;
                    }
                }
            }
            .dis-sec {
                padding: 30px;
                color: #8DBF4C;
                label {
                    font-size: 18px;
                    font-family: 'ROBOTO-BOLD';
                    color: #2F38AF
                }
                ul {
                    list-style: none;
                    li {
                        font-size: 16px;
                        font-family: 'ROBOTO-REGULAR';
                        padding: 10px 0;
                        display: flex;
                        i {}
                        span {
                            display: block;
                            margin-left: 10px;
                            margin-top: -5px;
                        }
                    }
                }
                .steps-sec {
                    font-size: 14px;
                    color: #707070;
                    h5 {
                        font-family: 'ROBOTO-BOLD';
                        font-size: 14px;
                    }
                    p {
                        font-family: 'Roboto-Regular';
                    }
                }
            }
        }
        .plan-se2 {
            border: 2px solid #8DBF4C;
            padding-bottom: 278px;
            h3 {
                color: #595959 !important;
            }
            .price-sec {
                button {
                    color: #fff !important;
                    background: #8DBF4C !important;
                }
            }
        }
        .plan-se3 {
            border: 2px solid #D4C623;
            h3 {
                color: #E2C214 !important;
            }
        }
    }
    .back-sec3 {
        position: relative;
        min-height: 1000px;
        h1 {
            top: -84px;
            position: absolute;
            text-align: right;
            font-size: 2000%;
            font-family: 'Montserrat-Bold';
            color: #5499c730;
            line-height: 396px;
            text-transform: uppercase;
        }
        .content-sec3 {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 100px 0 100px;
            .container-text {
                width: 50%;
                color: #707070;
                padding: 0px 60px;
                h2 {
                    font-family: 'Montserrat-Bold';
                    font-size: 45px;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    padding-bottom: 50px;
                    color: #8DBF4C
                }
                p {
                    font-size: 20px;
                    font-family: 'Lato-Regular';
                    color: #707070;
                }
            }
        }
        .meta {
            text-align: right;
            padding-right: 80px;
            p {
                margin: 0;
                font-size: 14px;
                font-family: 'Lato-Bold';
                color: #707070;
            }
            a {
                margin: 0;
                font-size: 12px;
                font-family: 'Lato-Medium';
                color: #707070;
                text-decoration: underline;
            }
        }
    }
    .slidingText {
        font-size: 160px;
        font-family: 'Lato';
        color: #8c8c8c5f;
        margin-bottom: 30px;
        animation: slide 11s linear infinite;
        width: 2600px;
        padding-left: 50px;
        @media only screen and (max-width: 600px) {
            font-size: 80px;
            padding-left: 30px;
            width: 1300px;
        }
    }
    @keyframes slide {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
}

.img-gri {
    position: relative;
}

@media (max-width:1800px) {
    .discover-metawheel .grid-img-sec .text-conatiner {
        width: 86%;
        // height: 100%;
        // padding: 30px 30px 45px;
        min-height: 900px;

    }
    .discover-metawheel .grid-img-sec{
        height: 900px;
    }
}

@media (max-width:1400px) {
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec .grid-text h2 {
        font-size: 31px;
        padding: 0 0px 11px 0;
        line-height: 38px;
    }
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec .grid-text {
        padding: 15px;
    }
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec .grid-text h1 {
        font-size: 87px;
    }
    .discover-metawheel .grid-img-sec .text-conatiner {
        min-height: 1048px;
    }
    .discover-metawheel .grid-img-sec .text-conatiner {
        width: 86%;
        height: 100%;
        padding: 30px 30px 45px;
        min-height: 938px;
    }
    .discover-metawheel .grid-img-sec {
        height: 1100px;
    }
    .discover-metawheel .back-sec3 .content-sec3 {
        padding: 0px 30px 0 30px;
    }
    .discover-metawheel .back-sec3 {
        min-height: 1800px;
        overflow: hidden;
    }
    // .discover-metawheel .grid-plan-sec .plan-sec .plan-sec1 h3{
    // font-size: 26px;
    // }
}

@media (max-width:768px) {
    .discover-metawheel_banner_content {
        padding: 100px 0px 50px 30px;
    }
    .discover-metawheel_banner_content_cont {
        width: 100%;
    }
    .discover-metawheel_banner_content_rightImg {
        width: 100%;
    }
    .discover-metawheel .content-sec1 {
        padding: 50px 30px 0 30px;
    }
    .discover-metawheel .content-sec2 {
        padding: 40px 30px 0 30px;
    }
    .discover-metawheel .content-sec2 .container-text {
        width: 100%;
    }
    .discover-metawheel .content-sec2 .container-text .btn-sec1 {
        justify-content: center;
        flex-wrap: wrap;
        button {
            margin-bottom: 20px;
            margin-right: 0px !important;
        }
    }
    .discover-metawheel .grid-container {
        padding: 40px 30px 0 30px;
    }
    .discover-metawheel .grid-container .grid-section1 img {
        width: 100%;
        height: auto;
    }
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec {
        width: 100%;
    }
    .discover-metawheel .grid-container .grid-section1:nth-child(1) .grid-text-sec .grid-text {
        border-radius: 0px 0px 0 0;
    }
    .discover-metawheel .grid-container .grid-section1:nth-child(4) .grid-text-sec .grid-text {
        border-radius: 0px 0px 0 0;
    }
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec .grid-text {
        height: auto;
        padding: 15px;
    }
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec .grid-text h2 {
        padding: 0 0px 26px 0;
        line-height: 40px;
    }
    .discover-metawheel .grid-container .grid-section1 .grid-text-sec .grid-text h1 {
        font-size: 50px;
    }
    .discover-metawheel .grid-slider marquee {
        padding: 15px;
    }
    .discover-metawheel .grid-slider {
        min-height: 371px;
    }
    .discover-metawheel .grid-slider marquee {
        top: 440px;
    }
    .discover-metawheel .grid-img-sec .text-conatiner {
        top: 0px;
        width: 86%;
        left: 27px;
        // height: auto;
        // padding: 35px 0 0 70px
        // background-image: url('../../assets/images/metawheel/banner-img.png');
        // background-size: 100% 100%;
    }
    .discover-metawheel .grid-img-sec .text-conatiner h2 {
        font-size: 27px;
    }
    .discover-metawheel .grid-img-sec {
        min-height: 2500px;
    }
    .discover-metawheel .grid-plan-sec {
        padding: 80px 30px;
    }
    .discover-metawheel .grid-plan-sec h2 {
        font-size: 36px;
        padding-bottom: 0px;
    }
    .discover-metawheel .grid-plan-sec .top-space {
        margin-top: 0px;
    }
    .discover-metawheel .grid-plan-sec .plan-sec {
        margin-top: 20px !important;
    }
    .discover-metawheel .grid-plan-sec .plan-sec {
        min-height: auto;
    }
    .discover-metawheel .grid-plan-sec .plan-se2 {
        padding-bottom: 0px;
    }
    .discover-metawheel .back-sec3 .content-sec3 {
        padding: 0px 15px 0 15px;
        flex-wrap: wrap;
        position: relative;
    }
    .discover-metawheel .back-sec3 .content-sec3 .container-text {
        width: 100%;
        padding: 0px 15px;
    }
    .discover-metawheel .content-sec1 .container-text h2 {
        margin-top: 20px;
    }
    .discover-metawheel .content-sec2 .container-text h2 {
        padding-bottom: 15px;
        margin: 0;
        font-size: 40px;
    }
    .discover-metawheel .content-sec2 .container-text ul li {
        padding: 10px 0;
    }
    .discover-metawheel .back-sec3 {
        min-height: 1500px;
        overflow: hidden;
    }
    .discover-metawheel .content-sec2 .container-img {
        width: 100%;
    }
}