.vedio-sec .vedio-con .v-select {
    justify-content: space-between !important;
    .select-sec {
        width: 230px;
        display: flex;
        justify-content: end;
        position: relative;
        select {
            padding: 5px;
            width: 230px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 2px solid #BEA64C;
            border-radius: 5px;
            color: rgb(88, 88, 88);
            white-space: normal;
            height: 42px;
            line-height: 15px;
            font-size: 15px;
        }
    }
    .fa-caret-down {
        position: absolute;
        top: 12px;
        right: 9px;
        color: #BEA64C;
        font-size: 17px;
    }
}

.vedio-sec .vedio-con {
    h4 {
        position: absolute;
        top: -35px;
    }
}

.fusion-exe-detail-warper .vedio-sec .vedio-con .vedios-pagi p {
    font-size: 18px !important;
}

@media only screen and (max-width: 600px) {
    .vedio-sec .vedio-con .v-select .select-sec {
        margin: 0 auto;
        margin-top: 15px;
    }
}